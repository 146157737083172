
.weapon-background {
  background: url('../../assets/images/backgrounds/icon-background-2.png');
  background-size: calc(100% + 2px);
  position: relative;
  cursor: pointer;
}
.weapon-container .weapon-icon {
  height: 62px;
  width: 62px;
}
.weapon-icon:before:hover {
  display: none !important;
}
.weapon-icon, .talent-icon, .trait-icon {
    box-shadow: inset 0 2px 2px white;
}

.weapon-icon {
  /* background-size: calc(100% - 2px) !important; */
  height: 100%;
  box-sizing: border-box;
  background-position: center !important;
}
.weapon-icon[data-selected='true'] {
  border-image: url('../../assets/images/borders/border-30.png');
  border-image-slice: 15;
  border-image-width: 15px;
  border-style: solid;
  border-image-repeat: repeat;
}
.weapon-icon:hover {
  cursor: pointer;
}
.bw_1h_mace, .weapon-icon[data-id='1'] {
  background: url('../../assets/images/weapons/bw_1h_mace.png' ) center / calc(100% + 8px), url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.bw_dagger, .weapon-icon[data-id='2'] {
  background: url('../../assets/images/weapons/bw_dagger.png' ) center / calc(100% + 8px), url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.bw_flame_sword, .weapon-icon[data-id='3'] {
  background: url('../../assets/images/weapons/bw_flame_sword.png' ) center / calc(100% + 8px), url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.bw_sword, .weapon-icon[data-id='4'] {
  background: url('../../assets/images/weapons/bw_sword.png' ) center / calc(100% + 8px), url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.dr_1h_axe, .weapon-icon[data-id='5'] {
  background: url('../../assets/images/weapons/dr_1h_axe.png' ) center / calc(100% + 8px), url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.dr_1h_hammer, .weapon-icon[data-id='6'] {
  background: url('../../assets/images/weapons/dr_1h_hammer.png' ) center / calc(100% + 8px), url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.dr_2h_axe, .weapon-icon[data-id='7'] {
  background: url('../../assets/images/weapons/dr_2h_axe.png' ) center / calc(100% + 8px), url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.dr_2h_hammer, .weapon-icon[data-id='8'] {
  background: url('../../assets/images/weapons/dr_2h_hammer.png' ) center / calc(100% + 8px), url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.dr_2h_pick, .weapon-icon[data-id='9'] {
  background: url('../../assets/images/weapons/dr_2h_pick.png' ) center / calc(100% + 8px), url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.dr_dual_wield_axes, .weapon-icon[data-id='10'] {
  background: url('../../assets/images/weapons/dr_dual_wield_axes.png' ) center / calc(100% + 8px), url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.dr_shield_axe, .weapon-icon[data-id='11'] {
  background: url('../../assets/images/weapons/dr_shield_axe.png' ) center / calc(100% + 8px), url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.dr_shield_hammer, .weapon-icon[data-id='12'] {
  background: url('../../assets/images/weapons/dr_shield_hammer.png' ) center / calc(100% + 8px), url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.es_1h_flail, .weapon-icon[data-id='13'] {
  background: url('../../assets/images/weapons/es_1h_flail.png' ) center / calc(100% + 8px), url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.es_1h_mace, .weapon-icon[data-id='14'] {
  background: url('../../assets/images/weapons/es_1h_mace.png' ) center / calc(100% + 8px), url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.es_1h_sword, .weapon-icon[data-id='15'] {
  background: url('../../assets/images/weapons/es_1h_sword.png' ) center / calc(100% + 8px), url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.es_2h_hammer, .weapon-icon[data-id='16'] {
  background: url('../../assets/images/weapons/es_2h_hammer.png') center / calc(100% + 8px), url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.es_2h_sword, .weapon-icon[data-id='17'] {
  background: url('../../assets/images/weapons/es_2h_sword.png' ) center / calc(100% + 8px), url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.es_2h_sword_executioner, .weapon-icon[data-id='18'] {
  background: url('../../assets/images/weapons/es_2h_sword_executioner.png' ) center / calc(100% + 8px), url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.es_halberd, .weapon-icon[data-id='19'] {
  background: url('../../assets/images/weapons/es_halberd.png' ) center / calc(100% + 8px), url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.es_mace_shield, .weapon-icon[data-id='20'] {
  background: url('../../assets/images/weapons/es_mace_shield.png' ) center / calc(100% + 8px), url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.es_sword_shield, .weapon-icon[data-id='21'] {
  background: url('../../assets/images/weapons/es_sword_shield.png' ) center / calc(100% + 8px), url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.we_1h_sword, .weapon-icon[data-id='22'] {
  background: url('../../assets/images/weapons/we_1h_sword.png' ) center / calc(100% + 8px), url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.we_2h_axe, .weapon-icon[data-id='23'] {
  background: url('../../assets/images/weapons/we_2h_axe.png' ) center / calc(100% + 8px), url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.we_2h_sword, .weapon-icon[data-id='24'] {
  background: url('../../assets/images/weapons/we_2h_sword.png' ) center / calc(100% + 8px), url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.we_dual_wield_daggers, .weapon-icon[data-id='25'] {
  background: url('../../assets/images/weapons/we_dual_wield_daggers.png' ) center / calc(100% + 8px), url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.we_dual_wield_sword_dagger, .weapon-icon[data-id='26'] {
  background: url('../../assets/images/weapons/we_dual_wield_sword_dagger.png' ) center / calc(100% + 8px), url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.we_dual_wield_swords, .weapon-icon[data-id='27'] {
  background: url('../../assets/images/weapons/we_dual_wield_swords.png' ) center / calc(100% + 8px), url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.we_spear, .weapon-icon[data-id='28'] {
  background: url('../../assets/images/weapons/we_spear.png' ) center / calc(100% + 8px), url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.wh_1h_axe, .weapon-icon[data-id='29'] {
  background: url('../../assets/images/weapons/wh_1h_axe.png' ) center / calc(100% + 8px), url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.wh_1h_falchion, .weapon-icon[data-id='30'] {
  background: url('../../assets/images/weapons/wh_1h_falchion.png' ) center / calc(100% + 8px), url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.wh_2h_sword, .weapon-icon[data-id='31'] {
  background: url('../../assets/images/weapons/wh_2h_sword.png' ) center / calc(100% + 8px), url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.wh_fencing_sword, .weapon-icon[data-id='32'] {
  background: url('../../assets/images/weapons/wh_fencing_sword.png' ) center / calc(100% + 8px), url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}

.es_mace_and_sword, .weapon-icon[data-id='33'] {
  background: url('../images/weapons/es_mace_and_sword.png' ) center / calc(100% + 8px), url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.es_spear, .weapon-icon[data-id='34'] {
  background: url('../images/weapons/es_spear.png' ) center / calc(100% + 8px), url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.we_axe, .weapon-icon[data-id='35'] {
  background: url('../images/weapons/we_axe.png' ) center / calc(100% + 8px), url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.we_spear_and_shield, .weapon-icon[data-id='36'] {
  background: url('../images/weapons/we_spear_and_shield.png' ) center / calc(100% + 8px), url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.dr_hammers, .weapon-icon[data-id='37'] {
  background: url('../images/weapons/dr_hammers.png' ) center / calc(100% + 8px), url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.wh_axe_and_falchion, .weapon-icon[data-id='38'] {
  background: url('../images/weapons/wh_axe_and_falchion.png' ) center / calc(100% + 8px), url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.wh_billhook, .weapon-icon[data-id='39'] {
  background: url('../images/weapons/wh_billhook.png' ) center / calc(100% + 8px), url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.bw_crowbill, .weapon-icon[data-id='40'] {
  background: url('../images/weapons/bw_crowbill.png' ) center / calc(100% + 8px), url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.bw_flail, .weapon-icon[data-id='41'] {
  background: url('../images/weapons/bw_flail.png' ) center / calc(100% + 8px), url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.es_1h_sword_shield_breton, .weapon-icon[data-id='42'] {
  background: url('../images/weapons/es_1h_sword_shield_breton.png' ) center / calc(100% + 8px), url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.es_bastard_sword, .weapon-icon[data-id='43'] {
  background: url('../images/weapons/es_bastard_sword.png' ) center / calc(100% + 8px), url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.es_bastard_sword, .weapon-icon[data-id='44'] {
  background: url('../images/weapons/dr_2h_cog_hammer.png' ) center / calc(100% + 8px), url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}



.bw_skullstaff_beam, .weapon-icon[data-id='45'] {
  background: url('../../assets/images/weapons/bw_skullstaff_beam.png' ) center / calc(100% + 8px), url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.bw_skullstaff_fireball, .weapon-icon[data-id='46'] {
  background: url('../../assets/images/weapons/bw_skullstaff_fireball.png' ) center / calc(100% + 8px), url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.bw_skullstaff_flamethrower, .weapon-icon[data-id='47'] {
  background: url('../../assets/images/weapons/bw_skullstaff_flamethrower.png' ) center / calc(100% + 8px), url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.bw_skullstaff_geiser, .weapon-icon[data-id='48'] {
  background: url('../../assets/images/weapons/bw_skullstaff_geiser.png' ) center / calc(100% + 8px), url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.bw_skullstaff_spear, .weapon-icon[data-id='49'] {
  background: url('../../assets/images/weapons/bw_skullstaff_spear.png' ) center / calc(100% + 8px), url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.dr_crossbow, .weapon-icon[data-id='50'] {
  background: url('../../assets/images/weapons/dr_crossbow.png' ) center / calc(100% + 8px), url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.dr_drake_pistol, .weapon-icon[data-id='51'] {
  background: url('../../assets/images/weapons/dr_drake_pistol.png' ) center / calc(100% + 8px), url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.dr_drakegun, .weapon-icon[data-id='52'] {
  background: url('../../assets/images/weapons/dr_drakegun.png' ) center / calc(100% + 8px), url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.dr_handgun, .weapon-icon[data-id='53'] {
  background: url('../../assets/images/weapons/dr_handgun.png' ) center / calc(100% + 8px), url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.dr_rakegun, .weapon-icon[data-id='54'] {
  background: url('../../assets/images/weapons/dr_rakegun.png' ) center / calc(100% + 8px), url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.es_blunderbuss, .weapon-icon[data-id='55'] {
  background: url('../../assets/images/weapons/es_blunderbuss.png' ) center / calc(100% + 8px), url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.es_handgun, .weapon-icon[data-id='56'] {
  background: url('../../assets/images/weapons/es_handgun.png' ) center / calc(100% + 8px), url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.es_longbow, .weapon-icon[data-id='57'] {
  background: url('../../assets/images/weapons/es_longbow.png' ) center / calc(100% + 8px), url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.es_repeating_handgun, .weapon-icon[data-id='58'] {
  background: url('../../assets/images/weapons/es_repeating_handgun.png' ) center / calc(100% + 8px), url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.we_crossbow_repeater, .weapon-icon[data-id='59'] {
  background: url('../../assets/images/weapons/we_crossbow_repeater.png' ) center / calc(100% + 8px), url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.we_longbow, .weapon-icon[data-id='60'] {
  background: url('../../assets/images/weapons/we_longbow.png' ) center / calc(100% + 8px), url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.we_shortbow, .weapon-icon[data-id='61'] {
  background: url('../../assets/images/weapons/we_shortbow.png' ) center / calc(100% + 8px), url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.we_shortbow_hagbane, .weapon-icon[data-id='62'] {
  background: url('../../assets/images/weapons/we_shortbow_hagbane.png' ) center / calc(100% + 8px), url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.wh_brace_of_pistols, .weapon-icon[data-id='63'] {
  background: url('../../assets/images/weapons/wh_brace_of_pistols.png' ) center / calc(100% + 8px), url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.wh_crossbow, .weapon-icon[data-id='64'] {
  background: url('../../assets/images/weapons/wh_crossbow.png' ) center / calc(100% + 8px), url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.wh_crossbow_repeater, .weapon-icon[data-id='65'] {
  background: url('../../assets/images/weapons/wh_crossbow_repeater.png' ) center / calc(100% + 8px), url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.wh_repeating_pistols, .weapon-icon[data-id='66'] {
  background: url('../../assets/images/weapons/wh_repeating_pistols.png' ) center / calc(100% + 8px), url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.weapon-icon[data-id='67'] {
  background: url('../../assets/images/weapons/dr_throwing_axes.png' ) center / calc(100% + 8px), url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.weapon-icon[data-id='68'] {
  background: url('../../assets/images/weapons/dr_steam_pistol.png' ) center / calc(100% + 8px), url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.weapon-icon[data-id='69'] {
  background: url('../../assets/images/weapons/es_deus_01.png' ) center / calc(100% + 8px), url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.weapon-icon[data-id='70'] {
  background: url('../../assets/images/weapons/dr_deus_01.png' ) center / calc(100% + 8px), url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.weapon-icon[data-id='71'] {
  background: url('../../assets/images/weapons/we_deus_01.png' ) center / calc(100% + 8px), url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.weapon-icon[data-id='72'] {
  background: url('../../assets/images/weapons/wh_deus_01.png' ) center / calc(100% + 8px), url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.weapon-icon[data-id='73'] {
  background: url('../../assets/images/weapons/bw_deus_01.png') center / contain, url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.weapon-icon[data-id='74'] {
  background: url('../../assets/images/weapons/we_life_staff.png') center / contain, url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.weapon-icon[data-id='75'] {
  background: url('../../assets/images/weapons/we_javelin.png') center / contain, url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.weapon-icon[data-id='76'] {
  background: url('../../assets/images/weapons/wh_1h_hammer.png') center / contain, url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.weapon-icon[data-id='77'] {
  background: url('../../assets/images/weapons/wh_2h_hammer.png') center / contain, url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.weapon-icon[data-id='78'] {
  background: url('../../assets/images/weapons/wh_hammer_shield.png') center / contain, url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.weapon-icon[data-id='79'] {
  background: url('../../assets/images/weapons/wh_dual_hammer.png') center / contain, url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.weapon-icon[data-id='80'] {
  background: url('../../assets/images/weapons/wh_hammer_book.png') center / contain, url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}
.weapon-icon[data-id='81'] {
  background: url('../../assets/images/weapons/wh_flail_shield.png') center / contain, url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}

.weapon-icon[data-id='82'] {
  background: url('../../assets/images/weapons/bw_reaper.png') center / contain, url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}

.weapon-icon[data-id='83'] {
  background: url('../../assets/images/weapons/bw_soulsteal.png') center / contain, url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
}

.weapon-border {
  border-image: url('../images/borders/border-04.png' ) center / calc(100% + 8px), url('../../assets/images/backgrounds/icon-background-2.png') center / contain;
  border-image-slice: 15;
  border-image-width: 15px;
  border-style: solid;
  border-image-repeat: repeat;
  position: relative;
  box-sizing: border-box;
  height: 100%;
}
p.skill-cooldown-label {
    white-space: nowrap;
}
.health-bar, .cooldown-bar {
    width: 100%;
    border-radius: 2px;
    height: 35px;
    position: relative;
}
.health-bar::after, .cooldown-bar::after {
    border-image: url('../../assets/images/borders/border-11.png');
    border-image-slice: 15;
    border-image-width: 15px;
    border-image-repeat: repeat;
    border-style: solid;
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    z-index: 1;
    box-sizing: border-box;
}
.health-bar::before, .cooldown-bar::before {
    background-image: url('../../assets/images/backgrounds/background18.png');
    background-size: cover;
    background-repeat: no-repeat;
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
}
.health-value {
    color: #000;
}
.health-bar[data-value="100"] .stat-container {
    width: 55%;
}
.health-bar[data-value="120"] .stat-container {
    width: 66%;
}
.health-bar[data-value="125"] .stat-container {
    width: 69%;
}
.health-bar[data-value="150"] .stat-container {
    width: 83%;
}
.health-bar[data-value="180"] .stat-container {
    width: 100%;
}
.cooldown-bar[data-value="18"] .stat-container {
    width: 16%;
}
.cooldown-bar[data-value="20"] .stat-container {
    width: 16%;
}
.cooldown-bar[data-value="27"] .stat-container {
    width: 22.5%;
}
.cooldown-bar[data-value="30"] .stat-container {
    width: 25%;
}
.cooldown-bar[data-value="36"] .stat-container {
    width: 30%;
}
.cooldown-bar[data-value="40"] .stat-container {
    width: 33%;
}
.cooldown-bar[data-value="45"] .stat-container {
    width: 37.2%;
}
.cooldown-bar[data-value="50"] .stat-container {
    width: 42%;
}
.cooldown-bar[data-value="54"] .stat-container {
    width: 45%;
}
.cooldown-bar[data-value="60"] .stat-container {
    width: 50%;
}
.cooldown-bar[data-value="63"] .stat-container {
    width: 52.5%;
}
.cooldown-bar[data-value="70"] .stat-container {
    width: 58%;
}
.cooldown-bar[data-value="72"] .stat-container {
    width: 60%;
}
.cooldown-bar[data-value="80"] .stat-container {
    width: 67%;
}
.cooldown-bar[data-value="81"] .stat-container {
    width: 67.5%;
}
.cooldown-bar[data-value="90"] .stat-container {
    width: 75%;
}
.cooldown-bar[data-value="99"] .stat-container {
    width: 82%;
}
.cooldown-bar[data-value="108"] .stat-container {
    width: 90%;
}
.cooldown-bar[data-value="110"] .stat-container {
    width: 92%;
}
.cooldown-bar[data-value="120"] .stat-container {
    width: 100%;
}
.health-bar .stat-container {    
  background: linear-gradient(90deg,#06730ab3,#31d014b3 100%), url('../../assets/images/backgrounds/background-42.png') center / cover;
}
.cooldown-bar .stat-container {    
    background: linear-gradient(90deg,#2b1d7eb3,#6c48a8b3 100%), url('../../assets/images/backgrounds/background-42.png') center / cover;
}
.stat-container {
  color: #f0d9af;
  justify-content: center;
  align-content: center;
  display: grid; 
  height: 100%;
  position: absolute;
  z-index: 1;  
  grid-auto-flow: column;
  grid-template-rows: 1fr;
  grid-auto-columns: auto;
  align-items: center;
  box-shadow: inset 0px 5px 2px 2px #ffffff60;
  font-size: 1.2em;  
  border-radius: 0 15px 15px 0;
}
.bonus-health {
  color: #000;
  text-shadow: none !important;
}
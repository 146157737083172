
/* .login-page .app-container-frame {
    width: 700px;
    height: 400px;
    left: calc(50% - 350px);
    top: calc(50vh - 200px);
  }
  .login-page .page-title {
    position: absolute;
    top: calc(50vh - 232px);
  }
  .login-page .page-title-label-background {
    top: calc(50vh - 222px);
  } */
  .login-page input {
    display: block;
    box-sizing: border-box;
    margin-bottom: 10px;
    padding: 4px;
    width: 220px;
    height: 42px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 15px;
    transition: 0.2s ease;
    text-align: center;
    width: 318px;
    background-color: #200000;
    text-align: center;
    color: white;
    outline: none;
  }
  .login-page form {
    display: grid;
    justify-content: center;
    position: relative;
    grid-template-columns: 1fr;
    grid-auto-flow: row;
    justify-items: center;
  }
  .login-page .app-container {
      display: grid;
      align-content: center;
  }
  .login-page {
    max-width: 800px;
    margin: 0 auto;
  }
  .login-page form {
      display: grid;
  }
  
  .login-page-tab, .register-page-tab {
      border-image: url('../../assets/images/borders/border-01.png');
      border-image-slice: 15;
      border-image-width: 15px;
      border-style: solid;
      border-image-repeat: repeat;
      padding: 20px;
  }
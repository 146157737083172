.talent-icon[data-career='1'][data-tier='1'][data-talent='1'] { background: url('../../assets/images/heroes/01/talents/talent-01.png'); }
.talent-icon[data-career='1'][data-tier='1'][data-talent='2'] { background: url('../../assets/images/heroes/01/talents/talent-02.png'); }
.talent-icon[data-career='1'][data-tier='1'][data-talent='3'] { background: url('../../assets/images/heroes/01/talents/talent-03.png'); }
.talent-icon[data-career='1'][data-tier='2'][data-talent='1'] { background: url('../../assets/images/heroes/01/talents/talent-04.png'); }
.talent-icon[data-career='1'][data-tier='2'][data-talent='2'] { background: url('../../assets/images/heroes/01/talents/talent-05.png'); }
.talent-icon[data-career='1'][data-tier='2'][data-talent='3'] { background: url('../../assets/images/heroes/01/talents/talent-06.png'); }
.talent-icon[data-career='1'][data-tier='3'][data-talent='1'] { background: url('../../assets/images/heroes/01/talents/talent-07.png'); }
.talent-icon[data-career='1'][data-tier='3'][data-talent='2'] { background: url('../../assets/images/heroes/01/talents/talent-08.png'); }
.talent-icon[data-career='1'][data-tier='3'][data-talent='3'] { background: url('../../assets/images/heroes/01/talents/talent-09.png'); }
.talent-icon[data-career='1'][data-tier='4'][data-talent='1'] { background: url('../../assets/images/heroes/01/talents/talent-10.png'); }
.talent-icon[data-career='1'][data-tier='4'][data-talent='2'] { background: url('../../assets/images/heroes/01/talents/talent-11.png'); }
.talent-icon[data-career='1'][data-tier='4'][data-talent='3'] { background: url('../../assets/images/heroes/01/talents/talent-12.png'); }
.talent-icon[data-career='1'][data-tier='5'][data-talent='1'] { background: url('../../assets/images/heroes/01/talents/talent-13.png'); }
.talent-icon[data-career='1'][data-tier='5'][data-talent='2'] { background: url('../../assets/images/heroes/01/talents/talent-14.png'); }
.talent-icon[data-career='1'][data-tier='5'][data-talent='3'] { background: url('../../assets/images/heroes/01/talents/talent-15.png'); }
.talent-icon[data-career='1'][data-tier='6'][data-talent='1'] { background: url('../../assets/images/heroes/01/talents/talent-16.png'); }
.talent-icon[data-career='1'][data-tier='6'][data-talent='2'] { background: url('../../assets/images/heroes/01/talents/talent-17.png'); }
.talent-icon[data-career='1'][data-tier='6'][data-talent='3'] { background: url('../../assets/images/heroes/01/talents/talent-18.png'); }

.talent-icon[data-career='2'][data-tier='1'][data-talent='1'] { background: url('../../assets/images/heroes/02/talents/talent-01.png'); }
.talent-icon[data-career='2'][data-tier='1'][data-talent='2'] { background: url('../../assets/images/heroes/02/talents/talent-02.png'); }
.talent-icon[data-career='2'][data-tier='1'][data-talent='3'] { background: url('../../assets/images/heroes/02/talents/talent-03.png'); }
.talent-icon[data-career='2'][data-tier='2'][data-talent='1'] { background: url('../../assets/images/heroes/02/talents/talent-04.png'); }
.talent-icon[data-career='2'][data-tier='2'][data-talent='2'] { background: url('../../assets/images/heroes/02/talents/talent-05.png'); }
.talent-icon[data-career='2'][data-tier='2'][data-talent='3'] { background: url('../../assets/images/heroes/02/talents/talent-06.png'); }
.talent-icon[data-career='2'][data-tier='3'][data-talent='1'] { background: url('../../assets/images/heroes/02/talents/talent-07.png'); }
.talent-icon[data-career='2'][data-tier='3'][data-talent='2'] { background: url('../../assets/images/heroes/02/talents/talent-08.png'); }
.talent-icon[data-career='2'][data-tier='3'][data-talent='3'] { background: url('../../assets/images/heroes/02/talents/talent-09.png'); }
.talent-icon[data-career='2'][data-tier='4'][data-talent='1'] { background: url('../../assets/images/heroes/02/talents/talent-10.png'); }
.talent-icon[data-career='2'][data-tier='4'][data-talent='2'] { background: url('../../assets/images/heroes/02/talents/talent-11.png'); }
.talent-icon[data-career='2'][data-tier='4'][data-talent='3'] { background: url('../../assets/images/heroes/02/talents/talent-12.png'); }
.talent-icon[data-career='2'][data-tier='5'][data-talent='1'] { background: url('../../assets/images/heroes/02/talents/talent-13.png'); }
.talent-icon[data-career='2'][data-tier='5'][data-talent='2'] { background: url('../../assets/images/heroes/02/talents/talent-14.png'); }
.talent-icon[data-career='2'][data-tier='5'][data-talent='3'] { background: url('../../assets/images/heroes/02/talents/talent-15.png'); }
.talent-icon[data-career='2'][data-tier='6'][data-talent='1'] { background: url('../../assets/images/heroes/02/talents/talent-16.png'); }
.talent-icon[data-career='2'][data-tier='6'][data-talent='2'] { background: url('../../assets/images/heroes/02/talents/talent-17.png'); }
.talent-icon[data-career='2'][data-tier='6'][data-talent='3'] { background: url('../../assets/images/heroes/02/talents/talent-18.png'); }

.talent-icon[data-career='3'][data-tier='1'][data-talent='1'] { background: url('../../assets/images/heroes/03/talents/talent-01.png'); }
.talent-icon[data-career='3'][data-tier='1'][data-talent='2'] { background: url('../../assets/images/heroes/03/talents/talent-02.png'); }
.talent-icon[data-career='3'][data-tier='1'][data-talent='3'] { background: url('../../assets/images/heroes/03/talents/talent-03.png'); }
.talent-icon[data-career='3'][data-tier='2'][data-talent='1'] { background: url('../../assets/images/heroes/03/talents/talent-04.png'); }
.talent-icon[data-career='3'][data-tier='2'][data-talent='2'] { background: url('../../assets/images/heroes/03/talents/talent-05.png'); }
.talent-icon[data-career='3'][data-tier='2'][data-talent='3'] { background: url('../../assets/images/heroes/03/talents/talent-06.png'); }
.talent-icon[data-career='3'][data-tier='3'][data-talent='1'] { background: url('../../assets/images/heroes/03/talents/talent-07.png'); }
.talent-icon[data-career='3'][data-tier='3'][data-talent='2'] { background: url('../../assets/images/heroes/03/talents/talent-08.png'); }
.talent-icon[data-career='3'][data-tier='3'][data-talent='3'] { background: url('../../assets/images/heroes/03/talents/talent-09.png'); }
.talent-icon[data-career='3'][data-tier='4'][data-talent='1'] { background: url('../../assets/images/heroes/03/talents/talent-10.png'); }
.talent-icon[data-career='3'][data-tier='4'][data-talent='2'] { background: url('../../assets/images/heroes/03/talents/talent-11.png'); }
.talent-icon[data-career='3'][data-tier='4'][data-talent='3'] { background: url('../../assets/images/heroes/03/talents/talent-12.png'); }
.talent-icon[data-career='3'][data-tier='5'][data-talent='1'] { background: url('../../assets/images/heroes/03/talents/talent-13.png'); }
.talent-icon[data-career='3'][data-tier='5'][data-talent='2'] { background: url('../../assets/images/heroes/03/talents/talent-14.png'); }
.talent-icon[data-career='3'][data-tier='5'][data-talent='3'] { background: url('../../assets/images/heroes/03/talents/talent-15.png'); }
.talent-icon[data-career='3'][data-tier='6'][data-talent='1'] { background: url('../../assets/images/heroes/03/talents/talent-16.png'); }
.talent-icon[data-career='3'][data-tier='6'][data-talent='2'] { background: url('../../assets/images/heroes/03/talents/talent-17.png'); }
.talent-icon[data-career='3'][data-tier='6'][data-talent='3'] { background: url('../../assets/images/heroes/03/talents/talent-18.png'); }

.talent-icon[data-career='4'][data-tier='1'][data-talent='1'] { background: url('../../assets/images/heroes/04/talents/talent-01.png'); }
.talent-icon[data-career='4'][data-tier='1'][data-talent='2'] { background: url('../../assets/images/heroes/04/talents/talent-02.png'); }
.talent-icon[data-career='4'][data-tier='1'][data-talent='3'] { background: url('../../assets/images/heroes/04/talents/talent-03.png'); }
.talent-icon[data-career='4'][data-tier='2'][data-talent='1'] { background: url('../../assets/images/heroes/04/talents/talent-04.png'); }
.talent-icon[data-career='4'][data-tier='2'][data-talent='2'] { background: url('../../assets/images/heroes/04/talents/talent-05.png'); }
.talent-icon[data-career='4'][data-tier='2'][data-talent='3'] { background: url('../../assets/images/heroes/04/talents/talent-06.png'); }
.talent-icon[data-career='4'][data-tier='3'][data-talent='1'] { background: url('../../assets/images/heroes/04/talents/talent-07.png'); }
.talent-icon[data-career='4'][data-tier='3'][data-talent='2'] { background: url('../../assets/images/heroes/04/talents/talent-08.png'); }
.talent-icon[data-career='4'][data-tier='3'][data-talent='3'] { background: url('../../assets/images/heroes/04/talents/talent-09.png'); }
.talent-icon[data-career='4'][data-tier='4'][data-talent='1'] { background: url('../../assets/images/heroes/04/talents/talent-10.png'); }
.talent-icon[data-career='4'][data-tier='4'][data-talent='2'] { background: url('../../assets/images/heroes/04/talents/talent-11.png'); }
.talent-icon[data-career='4'][data-tier='4'][data-talent='3'] { background: url('../../assets/images/heroes/04/talents/talent-12.png'); }
.talent-icon[data-career='4'][data-tier='5'][data-talent='1'] { background: url('../../assets/images/heroes/04/talents/talent-13.png'); }
.talent-icon[data-career='4'][data-tier='5'][data-talent='2'] { background: url('../../assets/images/heroes/04/talents/talent-14.png'); }
.talent-icon[data-career='4'][data-tier='5'][data-talent='3'] { background: url('../../assets/images/heroes/04/talents/talent-15.png'); }
.talent-icon[data-career='4'][data-tier='6'][data-talent='1'] { background: url('../../assets/images/heroes/04/talents/talent-16.png'); }
.talent-icon[data-career='4'][data-tier='6'][data-talent='2'] { background: url('../../assets/images/heroes/04/talents/talent-17.png'); }
.talent-icon[data-career='4'][data-tier='6'][data-talent='3'] { background: url('../../assets/images/heroes/04/talents/talent-18.png'); }

.talent-icon[data-career='5'][data-tier='1'][data-talent='1'] { background: url('../../assets/images/heroes/05/talents/talent-01.png'); }
.talent-icon[data-career='5'][data-tier='1'][data-talent='2'] { background: url('../../assets/images/heroes/05/talents/talent-02.png'); }
.talent-icon[data-career='5'][data-tier='1'][data-talent='3'] { background: url('../../assets/images/heroes/05/talents/talent-03.png'); }
.talent-icon[data-career='5'][data-tier='2'][data-talent='1'] { background: url('../../assets/images/heroes/05/talents/talent-04.png'); }
.talent-icon[data-career='5'][data-tier='2'][data-talent='2'] { background: url('../../assets/images/heroes/05/talents/talent-05.png'); }
.talent-icon[data-career='5'][data-tier='2'][data-talent='3'] { background: url('../../assets/images/heroes/05/talents/talent-06.png'); }
.talent-icon[data-career='5'][data-tier='3'][data-talent='1'] { background: url('../../assets/images/heroes/05/talents/talent-07.png'); }
.talent-icon[data-career='5'][data-tier='3'][data-talent='2'] { background: url('../../assets/images/heroes/05/talents/talent-08.png'); }
.talent-icon[data-career='5'][data-tier='3'][data-talent='3'] { background: url('../../assets/images/heroes/05/talents/talent-09.png'); }
.talent-icon[data-career='5'][data-tier='4'][data-talent='1'] { background: url('../../assets/images/heroes/05/talents/talent-10.png'); }
.talent-icon[data-career='5'][data-tier='4'][data-talent='2'] { background: url('../../assets/images/heroes/05/talents/talent-11.png'); }
.talent-icon[data-career='5'][data-tier='4'][data-talent='3'] { background: url('../../assets/images/heroes/05/talents/talent-12.png'); }
.talent-icon[data-career='5'][data-tier='5'][data-talent='1'] { background: url('../../assets/images/heroes/05/talents/talent-13.png'); }
.talent-icon[data-career='5'][data-tier='5'][data-talent='2'] { background: url('../../assets/images/heroes/05/talents/talent-14.png'); }
.talent-icon[data-career='5'][data-tier='5'][data-talent='3'] { background: url('../../assets/images/heroes/05/talents/talent-15.png'); }
.talent-icon[data-career='5'][data-tier='6'][data-talent='1'] { background: url('../../assets/images/heroes/05/talents/talent-16.png'); }
.talent-icon[data-career='5'][data-tier='6'][data-talent='2'] { background: url('../../assets/images/heroes/05/talents/talent-17.png'); }
.talent-icon[data-career='5'][data-tier='6'][data-talent='3'] { background: url('../../assets/images/heroes/05/talents/talent-18.png'); }

.talent-icon[data-career='6'][data-tier='1'][data-talent='1'] { background: url('../../assets/images/heroes/06/talents/talent-01.png'); }
.talent-icon[data-career='6'][data-tier='1'][data-talent='2'] { background: url('../../assets/images/heroes/06/talents/talent-02.png'); }
.talent-icon[data-career='6'][data-tier='1'][data-talent='3'] { background: url('../../assets/images/heroes/06/talents/talent-03.png'); }
.talent-icon[data-career='6'][data-tier='2'][data-talent='1'] { background: url('../../assets/images/heroes/06/talents/talent-04.png'); }
.talent-icon[data-career='6'][data-tier='2'][data-talent='2'] { background: url('../../assets/images/heroes/06/talents/talent-05.png'); }
.talent-icon[data-career='6'][data-tier='2'][data-talent='3'] { background: url('../../assets/images/heroes/06/talents/talent-06.png'); }
.talent-icon[data-career='6'][data-tier='3'][data-talent='1'] { background: url('../../assets/images/heroes/06/talents/talent-07.png'); }
.talent-icon[data-career='6'][data-tier='3'][data-talent='2'] { background: url('../../assets/images/heroes/06/talents/talent-08.png'); }
.talent-icon[data-career='6'][data-tier='3'][data-talent='3'] { background: url('../../assets/images/heroes/06/talents/talent-09.png'); }
.talent-icon[data-career='6'][data-tier='4'][data-talent='1'] { background: url('../../assets/images/heroes/06/talents/talent-10.png'); }
.talent-icon[data-career='6'][data-tier='4'][data-talent='2'] { background: url('../../assets/images/heroes/06/talents/talent-11.png'); }
.talent-icon[data-career='6'][data-tier='4'][data-talent='3'] { background: url('../../assets/images/heroes/06/talents/talent-12.png'); }
.talent-icon[data-career='6'][data-tier='5'][data-talent='1'] { background: url('../../assets/images/heroes/06/talents/talent-13.png'); }
.talent-icon[data-career='6'][data-tier='5'][data-talent='2'] { background: url('../../assets/images/heroes/06/talents/talent-14.png'); }
.talent-icon[data-career='6'][data-tier='5'][data-talent='3'] { background: url('../../assets/images/heroes/06/talents/talent-15.png'); }
.talent-icon[data-career='6'][data-tier='6'][data-talent='1'] { background: url('../../assets/images/heroes/06/talents/talent-16.png'); }
.talent-icon[data-career='6'][data-tier='6'][data-talent='2'] { background: url('../../assets/images/heroes/06/talents/talent-17.png'); }
.talent-icon[data-career='6'][data-tier='6'][data-talent='3'] { background: url('../../assets/images/heroes/06/talents/talent-18.png'); }

.talent-icon[data-career='7'][data-tier='1'][data-talent='1'] { background: url('../../assets/images/heroes/07/talents/talent-01.png'); }
.talent-icon[data-career='7'][data-tier='1'][data-talent='2'] { background: url('../../assets/images/heroes/07/talents/talent-02.png'); }
.talent-icon[data-career='7'][data-tier='1'][data-talent='3'] { background: url('../../assets/images/heroes/07/talents/talent-03.png'); }
.talent-icon[data-career='7'][data-tier='2'][data-talent='1'] { background: url('../../assets/images/heroes/07/talents/talent-04.png'); }
.talent-icon[data-career='7'][data-tier='2'][data-talent='2'] { background: url('../../assets/images/heroes/07/talents/talent-05.png'); }
.talent-icon[data-career='7'][data-tier='2'][data-talent='3'] { background: url('../../assets/images/heroes/07/talents/talent-06.png'); }
.talent-icon[data-career='7'][data-tier='3'][data-talent='1'] { background: url('../../assets/images/heroes/07/talents/talent-07.png'); }
.talent-icon[data-career='7'][data-tier='3'][data-talent='2'] { background: url('../../assets/images/heroes/07/talents/talent-08.png'); }
.talent-icon[data-career='7'][data-tier='3'][data-talent='3'] { background: url('../../assets/images/heroes/07/talents/talent-09.png'); }
.talent-icon[data-career='7'][data-tier='4'][data-talent='1'] { background: url('../../assets/images/heroes/07/talents/talent-10.png'); }
.talent-icon[data-career='7'][data-tier='4'][data-talent='2'] { background: url('../../assets/images/heroes/07/talents/talent-11.png'); }
.talent-icon[data-career='7'][data-tier='4'][data-talent='3'] { background: url('../../assets/images/heroes/07/talents/talent-12.png'); }
.talent-icon[data-career='7'][data-tier='5'][data-talent='1'] { background: url('../../assets/images/heroes/07/talents/talent-13.png'); }
.talent-icon[data-career='7'][data-tier='5'][data-talent='2'] { background: url('../../assets/images/heroes/07/talents/talent-14.png'); }
.talent-icon[data-career='7'][data-tier='5'][data-talent='3'] { background: url('../../assets/images/heroes/07/talents/talent-15.png'); }
.talent-icon[data-career='7'][data-tier='6'][data-talent='1'] { background: url('../../assets/images/heroes/07/talents/talent-16.png'); }
.talent-icon[data-career='7'][data-tier='6'][data-talent='2'] { background: url('../../assets/images/heroes/07/talents/talent-17.png'); }
.talent-icon[data-career='7'][data-tier='6'][data-talent='3'] { background: url('../../assets/images/heroes/07/talents/talent-18.png'); }

.talent-icon[data-career='8'][data-tier='1'][data-talent='1'] { background: url('../../assets/images/heroes/08/talents/talent-01.png'); }
.talent-icon[data-career='8'][data-tier='1'][data-talent='2'] { background: url('../../assets/images/heroes/08/talents/talent-02.png'); }
.talent-icon[data-career='8'][data-tier='1'][data-talent='3'] { background: url('../../assets/images/heroes/08/talents/talent-03.png'); }
.talent-icon[data-career='8'][data-tier='2'][data-talent='1'] { background: url('../../assets/images/heroes/08/talents/talent-04.png'); }
.talent-icon[data-career='8'][data-tier='2'][data-talent='2'] { background: url('../../assets/images/heroes/08/talents/talent-05.png'); }
.talent-icon[data-career='8'][data-tier='2'][data-talent='3'] { background: url('../../assets/images/heroes/08/talents/talent-06.png'); }
.talent-icon[data-career='8'][data-tier='3'][data-talent='1'] { background: url('../../assets/images/heroes/08/talents/talent-07.png'); }
.talent-icon[data-career='8'][data-tier='3'][data-talent='2'] { background: url('../../assets/images/heroes/08/talents/talent-08.png'); }
.talent-icon[data-career='8'][data-tier='3'][data-talent='3'] { background: url('../../assets/images/heroes/08/talents/talent-09.png'); }
.talent-icon[data-career='8'][data-tier='4'][data-talent='1'] { background: url('../../assets/images/heroes/08/talents/talent-10.png'); }
.talent-icon[data-career='8'][data-tier='4'][data-talent='2'] { background: url('../../assets/images/heroes/08/talents/talent-11.png'); }
.talent-icon[data-career='8'][data-tier='4'][data-talent='3'] { background: url('../../assets/images/heroes/08/talents/talent-12.png'); }
.talent-icon[data-career='8'][data-tier='5'][data-talent='1'] { background: url('../../assets/images/heroes/08/talents/talent-13.png'); }
.talent-icon[data-career='8'][data-tier='5'][data-talent='2'] { background: url('../../assets/images/heroes/08/talents/talent-14.png'); }
.talent-icon[data-career='8'][data-tier='5'][data-talent='3'] { background: url('../../assets/images/heroes/08/talents/talent-15.png'); }
.talent-icon[data-career='8'][data-tier='6'][data-talent='1'] { background: url('../../assets/images/heroes/08/talents/talent-16.png'); }
.talent-icon[data-career='8'][data-tier='6'][data-talent='2'] { background: url('../../assets/images/heroes/08/talents/talent-17.png'); }
.talent-icon[data-career='8'][data-tier='6'][data-talent='3'] { background: url('../../assets/images/heroes/08/talents/talent-18.png'); }

.talent-icon[data-career='9'][data-tier='1'][data-talent='1'] { background: url('../../assets/images/heroes/09/talents/talent-01.png'); }
.talent-icon[data-career='9'][data-tier='1'][data-talent='2'] { background: url('../../assets/images/heroes/09/talents/talent-02.png'); }
.talent-icon[data-career='9'][data-tier='1'][data-talent='3'] { background: url('../../assets/images/heroes/09/talents/talent-03.png'); }
.talent-icon[data-career='9'][data-tier='2'][data-talent='1'] { background: url('../../assets/images/heroes/09/talents/talent-04.png'); }
.talent-icon[data-career='9'][data-tier='2'][data-talent='2'] { background: url('../../assets/images/heroes/09/talents/talent-05.png'); }
.talent-icon[data-career='9'][data-tier='2'][data-talent='3'] { background: url('../../assets/images/heroes/09/talents/talent-06.png'); }
.talent-icon[data-career='9'][data-tier='3'][data-talent='1'] { background: url('../../assets/images/heroes/09/talents/talent-07.png'); }
.talent-icon[data-career='9'][data-tier='3'][data-talent='2'] { background: url('../../assets/images/heroes/09/talents/talent-08.png'); }
.talent-icon[data-career='9'][data-tier='3'][data-talent='3'] { background: url('../../assets/images/heroes/09/talents/talent-09.png'); }
.talent-icon[data-career='9'][data-tier='4'][data-talent='1'] { background: url('../../assets/images/heroes/09/talents/talent-10.png'); }
.talent-icon[data-career='9'][data-tier='4'][data-talent='2'] { background: url('../../assets/images/heroes/09/talents/talent-11.png'); }
.talent-icon[data-career='9'][data-tier='4'][data-talent='3'] { background: url('../../assets/images/heroes/09/talents/talent-12.png'); }
.talent-icon[data-career='9'][data-tier='5'][data-talent='1'] { background: url('../../assets/images/heroes/09/talents/talent-13.png'); }
.talent-icon[data-career='9'][data-tier='5'][data-talent='2'] { background: url('../../assets/images/heroes/09/talents/talent-14.png'); }
.talent-icon[data-career='9'][data-tier='5'][data-talent='3'] { background: url('../../assets/images/heroes/09/talents/talent-15.png'); }
.talent-icon[data-career='9'][data-tier='6'][data-talent='1'] { background: url('../../assets/images/heroes/09/talents/talent-16.png'); }
.talent-icon[data-career='9'][data-tier='6'][data-talent='2'] { background: url('../../assets/images/heroes/09/talents/talent-17.png'); }
.talent-icon[data-career='9'][data-tier='6'][data-talent='3'] { background: url('../../assets/images/heroes/09/talents/talent-18.png'); }

.talent-icon[data-career='10'][data-tier='1'][data-talent='1'] { background: url('../../assets/images/heroes/10/talents/talent-01.png'); }
.talent-icon[data-career='10'][data-tier='1'][data-talent='2'] { background: url('../../assets/images/heroes/10/talents/talent-02.png'); }
.talent-icon[data-career='10'][data-tier='1'][data-talent='3'] { background: url('../../assets/images/heroes/10/talents/talent-03.png'); }
.talent-icon[data-career='10'][data-tier='2'][data-talent='1'] { background: url('../../assets/images/heroes/10/talents/talent-04.png'); }
.talent-icon[data-career='10'][data-tier='2'][data-talent='2'] { background: url('../../assets/images/heroes/10/talents/talent-05.png'); }
.talent-icon[data-career='10'][data-tier='2'][data-talent='3'] { background: url('../../assets/images/heroes/10/talents/talent-06.png'); }
.talent-icon[data-career='10'][data-tier='3'][data-talent='1'] { background: url('../../assets/images/heroes/10/talents/talent-07.png'); }
.talent-icon[data-career='10'][data-tier='3'][data-talent='2'] { background: url('../../assets/images/heroes/10/talents/talent-08.png'); }
.talent-icon[data-career='10'][data-tier='3'][data-talent='3'] { background: url('../../assets/images/heroes/10/talents/talent-09.png'); }
.talent-icon[data-career='10'][data-tier='4'][data-talent='1'] { background: url('../../assets/images/heroes/10/talents/talent-10.png'); }
.talent-icon[data-career='10'][data-tier='4'][data-talent='2'] { background: url('../../assets/images/heroes/10/talents/talent-11.png'); }
.talent-icon[data-career='10'][data-tier='4'][data-talent='3'] { background: url('../../assets/images/heroes/10/talents/talent-12.png'); }
.talent-icon[data-career='10'][data-tier='5'][data-talent='1'] { background: url('../../assets/images/heroes/10/talents/talent-13.png'); }
.talent-icon[data-career='10'][data-tier='5'][data-talent='2'] { background: url('../../assets/images/heroes/10/talents/talent-14.png'); }
.talent-icon[data-career='10'][data-tier='5'][data-talent='3'] { background: url('../../assets/images/heroes/10/talents/talent-15.png'); }
.talent-icon[data-career='10'][data-tier='6'][data-talent='1'] { background: url('../../assets/images/heroes/10/talents/talent-16.png'); }
.talent-icon[data-career='10'][data-tier='6'][data-talent='2'] { background: url('../../assets/images/heroes/10/talents/talent-17.png'); }
.talent-icon[data-career='10'][data-tier='6'][data-talent='3'] { background: url('../../assets/images/heroes/10/talents/talent-18.png'); }

.talent-icon[data-career='11'][data-tier='1'][data-talent='1'] { background: url('../../assets/images/heroes/11/talents/talent-01.png'); }
.talent-icon[data-career='11'][data-tier='1'][data-talent='2'] { background: url('../../assets/images/heroes/11/talents/talent-02.png'); }
.talent-icon[data-career='11'][data-tier='1'][data-talent='3'] { background: url('../../assets/images/heroes/11/talents/talent-03.png'); }
.talent-icon[data-career='11'][data-tier='2'][data-talent='1'] { background: url('../../assets/images/heroes/11/talents/talent-04.png'); }
.talent-icon[data-career='11'][data-tier='2'][data-talent='2'] { background: url('../../assets/images/heroes/11/talents/talent-05.png'); }
.talent-icon[data-career='11'][data-tier='2'][data-talent='3'] { background: url('../../assets/images/heroes/11/talents/talent-06.png'); }
.talent-icon[data-career='11'][data-tier='3'][data-talent='1'] { background: url('../../assets/images/heroes/11/talents/talent-07.png'); }
.talent-icon[data-career='11'][data-tier='3'][data-talent='2'] { background: url('../../assets/images/heroes/11/talents/talent-08.png'); }
.talent-icon[data-career='11'][data-tier='3'][data-talent='3'] { background: url('../../assets/images/heroes/11/talents/talent-09.png'); }
.talent-icon[data-career='11'][data-tier='4'][data-talent='1'] { background: url('../../assets/images/heroes/11/talents/talent-10.png'); }
.talent-icon[data-career='11'][data-tier='4'][data-talent='2'] { background: url('../../assets/images/heroes/11/talents/talent-11.png'); }
.talent-icon[data-career='11'][data-tier='4'][data-talent='3'] { background: url('../../assets/images/heroes/11/talents/talent-12.png'); }
.talent-icon[data-career='11'][data-tier='5'][data-talent='1'] { background: url('../../assets/images/heroes/11/talents/talent-13.png'); }
.talent-icon[data-career='11'][data-tier='5'][data-talent='2'] { background: url('../../assets/images/heroes/11/talents/talent-14.png'); }
.talent-icon[data-career='11'][data-tier='5'][data-talent='3'] { background: url('../../assets/images/heroes/11/talents/talent-15.png'); }
.talent-icon[data-career='11'][data-tier='6'][data-talent='1'] { background: url('../../assets/images/heroes/11/talents/talent-16.png'); }
.talent-icon[data-career='11'][data-tier='6'][data-talent='2'] { background: url('../../assets/images/heroes/11/talents/talent-17.png'); }
.talent-icon[data-career='11'][data-tier='6'][data-talent='3'] { background: url('../../assets/images/heroes/11/talents/talent-18.png'); }

.talent-icon[data-career='12'][data-tier='1'][data-talent='1'] { background: url('../../assets/images/heroes/12/talents/talent-01.png'); }
.talent-icon[data-career='12'][data-tier='1'][data-talent='2'] { background: url('../../assets/images/heroes/12/talents/talent-02.png'); }
.talent-icon[data-career='12'][data-tier='1'][data-talent='3'] { background: url('../../assets/images/heroes/12/talents/talent-03.png'); }
.talent-icon[data-career='12'][data-tier='2'][data-talent='1'] { background: url('../../assets/images/heroes/12/talents/talent-04.png'); }
.talent-icon[data-career='12'][data-tier='2'][data-talent='2'] { background: url('../../assets/images/heroes/12/talents/talent-05.png'); }
.talent-icon[data-career='12'][data-tier='2'][data-talent='3'] { background: url('../../assets/images/heroes/12/talents/talent-06.png'); }
.talent-icon[data-career='12'][data-tier='3'][data-talent='1'] { background: url('../../assets/images/heroes/12/talents/talent-07.png'); }
.talent-icon[data-career='12'][data-tier='3'][data-talent='2'] { background: url('../../assets/images/heroes/12/talents/talent-08.png'); }
.talent-icon[data-career='12'][data-tier='3'][data-talent='3'] { background: url('../../assets/images/heroes/12/talents/talent-09.png'); }
.talent-icon[data-career='12'][data-tier='4'][data-talent='1'] { background: url('../../assets/images/heroes/12/talents/talent-10.png'); }
.talent-icon[data-career='12'][data-tier='4'][data-talent='2'] { background: url('../../assets/images/heroes/12/talents/talent-11.png'); }
.talent-icon[data-career='12'][data-tier='4'][data-talent='3'] { background: url('../../assets/images/heroes/12/talents/talent-12.png'); }
.talent-icon[data-career='12'][data-tier='5'][data-talent='1'] { background: url('../../assets/images/heroes/12/talents/talent-13.png'); }
.talent-icon[data-career='12'][data-tier='5'][data-talent='2'] { background: url('../../assets/images/heroes/12/talents/talent-14.png'); }
.talent-icon[data-career='12'][data-tier='5'][data-talent='3'] { background: url('../../assets/images/heroes/12/talents/talent-15.png'); }
.talent-icon[data-career='12'][data-tier='6'][data-talent='1'] { background: url('../../assets/images/heroes/12/talents/talent-16.png'); }
.talent-icon[data-career='12'][data-tier='6'][data-talent='2'] { background: url('../../assets/images/heroes/12/talents/talent-17.png'); }
.talent-icon[data-career='12'][data-tier='6'][data-talent='3'] { background: url('../../assets/images/heroes/12/talents/talent-18.png'); }

.talent-icon[data-career='13'][data-tier='1'][data-talent='1'] { background: url('../../assets/images/heroes/13/talents/talent-01.png'); }
.talent-icon[data-career='13'][data-tier='1'][data-talent='2'] { background: url('../../assets/images/heroes/13/talents/talent-02.png'); }
.talent-icon[data-career='13'][data-tier='1'][data-talent='3'] { background: url('../../assets/images/heroes/13/talents/talent-03.png'); }
.talent-icon[data-career='13'][data-tier='2'][data-talent='1'] { background: url('../../assets/images/heroes/13/talents/talent-04.png'); }
.talent-icon[data-career='13'][data-tier='2'][data-talent='2'] { background: url('../../assets/images/heroes/13/talents/talent-05.png'); }
.talent-icon[data-career='13'][data-tier='2'][data-talent='3'] { background: url('../../assets/images/heroes/13/talents/talent-06.png'); }
.talent-icon[data-career='13'][data-tier='3'][data-talent='1'] { background: url('../../assets/images/heroes/13/talents/talent-07.png'); }
.talent-icon[data-career='13'][data-tier='3'][data-talent='2'] { background: url('../../assets/images/heroes/13/talents/talent-08.png'); }
.talent-icon[data-career='13'][data-tier='3'][data-talent='3'] { background: url('../../assets/images/heroes/13/talents/talent-09.png'); }
.talent-icon[data-career='13'][data-tier='4'][data-talent='1'] { background: url('../../assets/images/heroes/13/talents/talent-10.png'); }
.talent-icon[data-career='13'][data-tier='4'][data-talent='2'] { background: url('../../assets/images/heroes/13/talents/talent-11.png'); }
.talent-icon[data-career='13'][data-tier='4'][data-talent='3'] { background: url('../../assets/images/heroes/13/talents/talent-12.png'); }
.talent-icon[data-career='13'][data-tier='5'][data-talent='1'] { background: url('../../assets/images/heroes/13/talents/talent-13.png'); }
.talent-icon[data-career='13'][data-tier='5'][data-talent='2'] { background: url('../../assets/images/heroes/13/talents/talent-14.png'); }
.talent-icon[data-career='13'][data-tier='5'][data-talent='3'] { background: url('../../assets/images/heroes/13/talents/talent-15.png'); }
.talent-icon[data-career='13'][data-tier='6'][data-talent='1'] { background: url('../../assets/images/heroes/13/talents/talent-16.png'); }
.talent-icon[data-career='13'][data-tier='6'][data-talent='2'] { background: url('../../assets/images/heroes/13/talents/talent-17.png'); }
.talent-icon[data-career='13'][data-tier='6'][data-talent='3'] { background: url('../../assets/images/heroes/13/talents/talent-18.png'); }

.talent-icon[data-career='14'][data-tier='1'][data-talent='1'] { background: url('../../assets/images/heroes/14/talents/talent-01.png'); }
.talent-icon[data-career='14'][data-tier='1'][data-talent='2'] { background: url('../../assets/images/heroes/14/talents/talent-02.png'); }
.talent-icon[data-career='14'][data-tier='1'][data-talent='3'] { background: url('../../assets/images/heroes/14/talents/talent-03.png'); }
.talent-icon[data-career='14'][data-tier='2'][data-talent='1'] { background: url('../../assets/images/heroes/14/talents/talent-04.png'); }
.talent-icon[data-career='14'][data-tier='2'][data-talent='2'] { background: url('../../assets/images/heroes/14/talents/talent-05.png'); }
.talent-icon[data-career='14'][data-tier='2'][data-talent='3'] { background: url('../../assets/images/heroes/14/talents/talent-06.png'); }
.talent-icon[data-career='14'][data-tier='3'][data-talent='1'] { background: url('../../assets/images/heroes/14/talents/talent-07.png'); }
.talent-icon[data-career='14'][data-tier='3'][data-talent='2'] { background: url('../../assets/images/heroes/14/talents/talent-08.png'); }
.talent-icon[data-career='14'][data-tier='3'][data-talent='3'] { background: url('../../assets/images/heroes/14/talents/talent-09.png'); }
.talent-icon[data-career='14'][data-tier='4'][data-talent='1'] { background: url('../../assets/images/heroes/14/talents/talent-10.png'); }
.talent-icon[data-career='14'][data-tier='4'][data-talent='2'] { background: url('../../assets/images/heroes/14/talents/talent-11.png'); }
.talent-icon[data-career='14'][data-tier='4'][data-talent='3'] { background: url('../../assets/images/heroes/14/talents/talent-12.png'); }
.talent-icon[data-career='14'][data-tier='5'][data-talent='1'] { background: url('../../assets/images/heroes/14/talents/talent-13.png'); }
.talent-icon[data-career='14'][data-tier='5'][data-talent='2'] { background: url('../../assets/images/heroes/14/talents/talent-14.png'); }
.talent-icon[data-career='14'][data-tier='5'][data-talent='3'] { background: url('../../assets/images/heroes/14/talents/talent-15.png'); }
.talent-icon[data-career='14'][data-tier='6'][data-talent='1'] { background: url('../../assets/images/heroes/14/talents/talent-16.png'); }
.talent-icon[data-career='14'][data-tier='6'][data-talent='2'] { background: url('../../assets/images/heroes/14/talents/talent-17.png'); }
.talent-icon[data-career='14'][data-tier='6'][data-talent='3'] { background: url('../../assets/images/heroes/14/talents/talent-18.png'); }

.talent-icon[data-career='15'][data-tier='1'][data-talent='1'] { background: url('../../assets/images/heroes/15/talents/talent-01.png'); }
.talent-icon[data-career='15'][data-tier='1'][data-talent='2'] { background: url('../../assets/images/heroes/15/talents/talent-02.png'); }
.talent-icon[data-career='15'][data-tier='1'][data-talent='3'] { background: url('../../assets/images/heroes/15/talents/talent-03.png'); }
.talent-icon[data-career='15'][data-tier='2'][data-talent='1'] { background: url('../../assets/images/heroes/15/talents/talent-04.png'); }
.talent-icon[data-career='15'][data-tier='2'][data-talent='2'] { background: url('../../assets/images/heroes/15/talents/talent-05.png'); }
.talent-icon[data-career='15'][data-tier='2'][data-talent='3'] { background: url('../../assets/images/heroes/15/talents/talent-06.png'); }
.talent-icon[data-career='15'][data-tier='3'][data-talent='1'] { background: url('../../assets/images/heroes/15/talents/talent-07.png'); }
.talent-icon[data-career='15'][data-tier='3'][data-talent='2'] { background: url('../../assets/images/heroes/15/talents/talent-08.png'); }
.talent-icon[data-career='15'][data-tier='3'][data-talent='3'] { background: url('../../assets/images/heroes/15/talents/talent-09.png'); }
.talent-icon[data-career='15'][data-tier='4'][data-talent='1'] { background: url('../../assets/images/heroes/15/talents/talent-10.png'); }
.talent-icon[data-career='15'][data-tier='4'][data-talent='2'] { background: url('../../assets/images/heroes/15/talents/talent-11.png'); }
.talent-icon[data-career='15'][data-tier='4'][data-talent='3'] { background: url('../../assets/images/heroes/15/talents/talent-12.png'); }
.talent-icon[data-career='15'][data-tier='5'][data-talent='1'] { background: url('../../assets/images/heroes/15/talents/talent-13.png'); }
.talent-icon[data-career='15'][data-tier='5'][data-talent='2'] { background: url('../../assets/images/heroes/15/talents/talent-14.png'); }
.talent-icon[data-career='15'][data-tier='5'][data-talent='3'] { background: url('../../assets/images/heroes/15/talents/talent-15.png'); }
.talent-icon[data-career='15'][data-tier='6'][data-talent='1'] { background: url('../../assets/images/heroes/15/talents/talent-16.png'); }
.talent-icon[data-career='15'][data-tier='6'][data-talent='2'] { background: url('../../assets/images/heroes/15/talents/talent-17.png'); }
.talent-icon[data-career='15'][data-tier='6'][data-talent='3'] { background: url('../../assets/images/heroes/15/talents/talent-18.png'); }

.talent-icon[data-career='16'][data-tier='1'][data-talent='1'] { background: url('../../assets/images/heroes/16/talents/talent-01.png'); }
.talent-icon[data-career='16'][data-tier='1'][data-talent='2'] { background: url('../../assets/images/heroes/16/talents/talent-02.png'); }
.talent-icon[data-career='16'][data-tier='1'][data-talent='3'] { background: url('../../assets/images/heroes/16/talents/talent-03.png'); }
.talent-icon[data-career='16'][data-tier='2'][data-talent='1'] { background: url('../../assets/images/heroes/16/talents/talent-04.png'); }
.talent-icon[data-career='16'][data-tier='2'][data-talent='2'] { background: url('../../assets/images/heroes/16/talents/talent-05.png'); }
.talent-icon[data-career='16'][data-tier='2'][data-talent='3'] { background: url('../../assets/images/heroes/16/talents/talent-06.png'); }
.talent-icon[data-career='16'][data-tier='3'][data-talent='1'] { background: url('../../assets/images/heroes/16/talents/talent-07.png'); }
.talent-icon[data-career='16'][data-tier='3'][data-talent='2'] { background: url('../../assets/images/heroes/16/talents/talent-08.png'); }
.talent-icon[data-career='16'][data-tier='3'][data-talent='3'] { background: url('../../assets/images/heroes/16/talents/talent-09.png'); }
.talent-icon[data-career='16'][data-tier='4'][data-talent='1'] { background: url('../../assets/images/heroes/16/talents/talent-10.png'); }
.talent-icon[data-career='16'][data-tier='4'][data-talent='2'] { background: url('../../assets/images/heroes/16/talents/talent-11.png'); }
.talent-icon[data-career='16'][data-tier='4'][data-talent='3'] { background: url('../../assets/images/heroes/16/talents/talent-12.png'); }
.talent-icon[data-career='16'][data-tier='5'][data-talent='1'] { background: url('../../assets/images/heroes/16/talents/talent-13.png'); }
.talent-icon[data-career='16'][data-tier='5'][data-talent='2'] { background: url('../../assets/images/heroes/16/talents/talent-14.png'); }
.talent-icon[data-career='16'][data-tier='5'][data-talent='3'] { background: url('../../assets/images/heroes/16/talents/talent-15.png'); }
.talent-icon[data-career='16'][data-tier='6'][data-talent='1'] { background: url('../../assets/images/heroes/16/talents/talent-16.png'); }
.talent-icon[data-career='16'][data-tier='6'][data-talent='2'] { background: url('../../assets/images/heroes/16/talents/talent-17.png'); }
.talent-icon[data-career='16'][data-tier='6'][data-talent='3'] { background: url('../../assets/images/heroes/16/talents/talent-18.png'); }

.talent-icon[data-career='17'][data-tier='1'][data-talent='1'] { background: url('../../assets/images/heroes/17/talents/talent-01.png'); }
.talent-icon[data-career='17'][data-tier='1'][data-talent='2'] { background: url('../../assets/images/heroes/17/talents/talent-02.png'); }
.talent-icon[data-career='17'][data-tier='1'][data-talent='3'] { background: url('../../assets/images/heroes/17/talents/talent-03.png'); }
.talent-icon[data-career='17'][data-tier='2'][data-talent='1'] { background: url('../../assets/images/heroes/17/talents/talent-04.png'); }
.talent-icon[data-career='17'][data-tier='2'][data-talent='2'] { background: url('../../assets/images/heroes/17/talents/talent-05.png'); }
.talent-icon[data-career='17'][data-tier='2'][data-talent='3'] { background: url('../../assets/images/heroes/17/talents/talent-06.png'); }
.talent-icon[data-career='17'][data-tier='3'][data-talent='1'] { background: url('../../assets/images/heroes/17/talents/talent-07.png'); }
.talent-icon[data-career='17'][data-tier='3'][data-talent='2'] { background: url('../../assets/images/heroes/17/talents/talent-08.png'); }
.talent-icon[data-career='17'][data-tier='3'][data-talent='3'] { background: url('../../assets/images/heroes/17/talents/talent-09.png'); }
.talent-icon[data-career='17'][data-tier='4'][data-talent='1'] { background: url('../../assets/images/heroes/17/talents/talent-10.png'); }
.talent-icon[data-career='17'][data-tier='4'][data-talent='2'] { background: url('../../assets/images/heroes/17/talents/talent-11.png'); }
.talent-icon[data-career='17'][data-tier='4'][data-talent='3'] { background: url('../../assets/images/heroes/17/talents/talent-12.png'); }
.talent-icon[data-career='17'][data-tier='5'][data-talent='1'] { background: url('../../assets/images/heroes/17/talents/talent-13.png'); }
.talent-icon[data-career='17'][data-tier='5'][data-talent='2'] { background: url('../../assets/images/heroes/17/talents/talent-14.png'); }
.talent-icon[data-career='17'][data-tier='5'][data-talent='3'] { background: url('../../assets/images/heroes/17/talents/talent-15.png'); }
.talent-icon[data-career='17'][data-tier='6'][data-talent='1'] { background: url('../../assets/images/heroes/17/talents/talent-16.png'); }
.talent-icon[data-career='17'][data-tier='6'][data-talent='2'] { background: url('../../assets/images/heroes/17/talents/talent-17.png'); }
.talent-icon[data-career='17'][data-tier='6'][data-talent='3'] { background: url('../../assets/images/heroes/17/talents/talent-18.png'); }

.talent-icon[data-career='18'][data-tier='1'][data-talent='1'] { background: url('../../assets/images/heroes/18/talents/talent-01.png'); }
.talent-icon[data-career='18'][data-tier='1'][data-talent='2'] { background: url('../../assets/images/heroes/18/talents/talent-02.png'); }
.talent-icon[data-career='18'][data-tier='1'][data-talent='3'] { background: url('../../assets/images/heroes/18/talents/talent-03.png'); }
.talent-icon[data-career='18'][data-tier='2'][data-talent='1'] { background: url('../../assets/images/heroes/18/talents/talent-04.png'); }
.talent-icon[data-career='18'][data-tier='2'][data-talent='2'] { background: url('../../assets/images/heroes/18/talents/talent-05.png'); }
.talent-icon[data-career='18'][data-tier='2'][data-talent='3'] { background: url('../../assets/images/heroes/18/talents/talent-06.png'); }
.talent-icon[data-career='18'][data-tier='3'][data-talent='1'] { background: url('../../assets/images/heroes/18/talents/talent-07.png'); }
.talent-icon[data-career='18'][data-tier='3'][data-talent='2'] { background: url('../../assets/images/heroes/18/talents/talent-08.png'); }
.talent-icon[data-career='18'][data-tier='3'][data-talent='3'] { background: url('../../assets/images/heroes/18/talents/talent-09.png'); }
.talent-icon[data-career='18'][data-tier='4'][data-talent='1'] { background: url('../../assets/images/heroes/18/talents/talent-10.png'); }
.talent-icon[data-career='18'][data-tier='4'][data-talent='2'] { background: url('../../assets/images/heroes/18/talents/talent-11.png'); }
.talent-icon[data-career='18'][data-tier='4'][data-talent='3'] { background: url('../../assets/images/heroes/18/talents/talent-12.png'); }
.talent-icon[data-career='18'][data-tier='5'][data-talent='1'] { background: url('../../assets/images/heroes/18/talents/talent-13.png'); }
.talent-icon[data-career='18'][data-tier='5'][data-talent='2'] { background: url('../../assets/images/heroes/18/talents/talent-14.png'); }
.talent-icon[data-career='18'][data-tier='5'][data-talent='3'] { background: url('../../assets/images/heroes/18/talents/talent-15.png'); }
.talent-icon[data-career='18'][data-tier='6'][data-talent='1'] { background: url('../../assets/images/heroes/18/talents/talent-16.png'); }
.talent-icon[data-career='18'][data-tier='6'][data-talent='2'] { background: url('../../assets/images/heroes/18/talents/talent-17.png'); }
.talent-icon[data-career='18'][data-tier='6'][data-talent='3'] { background: url('../../assets/images/heroes/18/talents/talent-18.png'); }

.talent-icon[data-career='19'][data-tier='1'][data-talent='1'] { background: url('../../assets/images/heroes/19/talents/talent-01.png'); }
.talent-icon[data-career='19'][data-tier='1'][data-talent='2'] { background: url('../../assets/images/heroes/19/talents/talent-02.png'); }
.talent-icon[data-career='19'][data-tier='1'][data-talent='3'] { background: url('../../assets/images/heroes/19/talents/talent-03.png'); }
.talent-icon[data-career='19'][data-tier='2'][data-talent='1'] { background: url('../../assets/images/heroes/19/talents/talent-04.png'); }
.talent-icon[data-career='19'][data-tier='2'][data-talent='2'] { background: url('../../assets/images/heroes/19/talents/talent-05.png'); }
.talent-icon[data-career='19'][data-tier='2'][data-talent='3'] { background: url('../../assets/images/heroes/19/talents/talent-06.png'); }
.talent-icon[data-career='19'][data-tier='3'][data-talent='1'] { background: url('../../assets/images/heroes/19/talents/talent-07.png'); }
.talent-icon[data-career='19'][data-tier='3'][data-talent='2'] { background: url('../../assets/images/heroes/19/talents/talent-08.png'); }
.talent-icon[data-career='19'][data-tier='3'][data-talent='3'] { background: url('../../assets/images/heroes/19/talents/talent-09.png'); }
.talent-icon[data-career='19'][data-tier='4'][data-talent='1'] { background: url('../../assets/images/heroes/19/talents/talent-10.png'); }
.talent-icon[data-career='19'][data-tier='4'][data-talent='2'] { background: url('../../assets/images/heroes/19/talents/talent-11.png'); }
.talent-icon[data-career='19'][data-tier='4'][data-talent='3'] { background: url('../../assets/images/heroes/19/talents/talent-12.png'); }
.talent-icon[data-career='19'][data-tier='5'][data-talent='1'] { background: url('../../assets/images/heroes/19/talents/talent-13.png'); }
.talent-icon[data-career='19'][data-tier='5'][data-talent='2'] { background: url('../../assets/images/heroes/19/talents/talent-14.png'); }
.talent-icon[data-career='19'][data-tier='5'][data-talent='3'] { background: url('../../assets/images/heroes/19/talents/talent-15.png'); }
.talent-icon[data-career='19'][data-tier='6'][data-talent='1'] { background: url('../../assets/images/heroes/19/talents/talent-16.png'); }
.talent-icon[data-career='19'][data-tier='6'][data-talent='2'] { background: url('../../assets/images/heroes/19/talents/talent-17.png'); }
.talent-icon[data-career='19'][data-tier='6'][data-talent='3'] { background: url('../../assets/images/heroes/19/talents/talent-18.png'); }

.talent-icon[data-career='20'][data-tier='1'][data-talent='1'] { background: url('../../assets/images/heroes/20/talents/talent-01.png'); }
.talent-icon[data-career='20'][data-tier='1'][data-talent='2'] { background: url('../../assets/images/heroes/20/talents/talent-02.png'); }
.talent-icon[data-career='20'][data-tier='1'][data-talent='3'] { background: url('../../assets/images/heroes/20/talents/talent-03.png'); }
.talent-icon[data-career='20'][data-tier='2'][data-talent='1'] { background: url('../../assets/images/heroes/20/talents/talent-04.png'); }
.talent-icon[data-career='20'][data-tier='2'][data-talent='2'] { background: url('../../assets/images/heroes/20/talents/talent-05.png'); }
.talent-icon[data-career='20'][data-tier='2'][data-talent='3'] { background: url('../../assets/images/heroes/20/talents/talent-06.png'); }
.talent-icon[data-career='20'][data-tier='3'][data-talent='1'] { background: url('../../assets/images/heroes/20/talents/talent-07.png'); }
.talent-icon[data-career='20'][data-tier='3'][data-talent='2'] { background: url('../../assets/images/heroes/20/talents/talent-08.png'); }
.talent-icon[data-career='20'][data-tier='3'][data-talent='3'] { background: url('../../assets/images/heroes/20/talents/talent-09.png'); }
.talent-icon[data-career='20'][data-tier='4'][data-talent='1'] { background: url('../../assets/images/heroes/20/talents/talent-10.png'); }
.talent-icon[data-career='20'][data-tier='4'][data-talent='2'] { background: url('../../assets/images/heroes/20/talents/talent-11.png'); }
.talent-icon[data-career='20'][data-tier='4'][data-talent='3'] { background: url('../../assets/images/heroes/20/talents/talent-12.png'); }
.talent-icon[data-career='20'][data-tier='5'][data-talent='1'] { background: url('../../assets/images/heroes/20/talents/talent-13.png'); }
.talent-icon[data-career='20'][data-tier='5'][data-talent='2'] { background: url('../../assets/images/heroes/20/talents/talent-14.png'); }
.talent-icon[data-career='20'][data-tier='5'][data-talent='3'] { background: url('../../assets/images/heroes/20/talents/talent-15.png'); }
.talent-icon[data-career='20'][data-tier='6'][data-talent='1'] { background: url('../../assets/images/heroes/20/talents/talent-16.png'); }
.talent-icon[data-career='20'][data-tier='6'][data-talent='2'] { background: url('../../assets/images/heroes/20/talents/talent-17.png'); }
.talent-icon[data-career='20'][data-tier='6'][data-talent='3'] { background: url('../../assets/images/heroes/20/talents/talent-18.png'); }

@media (max-width: 500px) {

    .build-list-item {
        grid-template-rows: unset;
        grid-template-columns: 120px auto auto;
        grid-template-areas:
            "heroIcon buildDescription buildRating"
            "heroIcon buildWeapons empty"
            "heroIcon buildTraits empty"
            "heroIcon buildFooter buildFooter";
    }
    .necklace-property-container {
        grid-area: necklacePropertyContainer;
    }
    .charm-property-container {
        grid-area: charmPropertyContainer;
    }
    .trinket-property-container {
        grid-area: trinketPropertyContainer;
    }
    .build-jewelry-summary .jewelry-icon, .build-jewelry-summary .trait-icon {
        width: 60px;
        justify-self: center;
        box-sizing: border-box;
    }
    .hero-character-icons>.character-icon {        
        width: auto !important;
        height: auto !important;
    }
    .hero-character-icons {
        grid-template-rows: 50px !important;
        grid-auto-columns: 1fr !important;
    }
    .hero-summary-container {
        grid-template-rows: auto auto auto !important;
        grid-template-columns: 1fr;
        grid-column-gap: 20px;
        grid-row-gap: 10px;
        grid-template-areas:
            "heroPortrait heroTalentsSummary"
            "heroName heroName"
            "heroAttributes heroAttributes" !important;
    }
    .app-container-frame {
        padding: 0;
        margin: 40px 0 0;
    }
    .app-container {
        width: auto;
        border-image: none;
        border-style: none;
        padding: 0;
        max-width: unset;
    }
    
    .app-container {
        margin: 0;
        padding: 0px;
        height: auto;
    }
    
    .page-title-label {
        display: none;
    }
    .hero-icon-container {
        grid-template-columns: 1fr 1fr !important;
        grid-template-rows: 1fr 1fr !important;
        grid-row-gap: 10px !important;
        justify-content: center;
    }
}
@media (max-width: 750px) {
    .build-list-item {
        grid-template-areas:
            "heroIcon heroIcon"
            "buildDescription buildRating"
            "buildWeapons buildWeapons"
            "buildTraits buildTraits"
            "buildFooter buildFooter" !important;
        grid-template-columns: 1fr auto !important;
        grid-template-rows: auto auto auto auto 40px !important;
        padding: 15px 0 0 15px;
    }
    .build-list-item .build-creation-info {
        grid-template-columns: min-content auto;
        grid-template-rows: auto auto;
        grid-auto-flow: row !important;
    }    
    .date-updated {
        grid-column: 1 / 3;
    }
    .rating {
        padding-right: 0px;
    }
    .build-hero-icon {
        height: 65px !important;
        width: calc(100% - 15px) !important;
        margin: 0;
        box-sizing: border-box;
        margin-left: 0 !important;
        margin-right: 15px !important;
    }
    .build-description-container, .build-list-item .rating {
        margin-top: 0px !important;
    }
    .build-list-item .hero-icon[data-career='1'] {
        background: url('../images/heroes/01/portrait-wide.png') no-repeat right / contain, url('../images/backgrounds/background29.png');
    }
    .build-list-item .hero-icon[data-career='2'] {
        background: url('../images/heroes/02/portrait-wide.png') no-repeat right / contain, url('../images/backgrounds/background29.png');
    }
    .build-list-item .hero-icon[data-career='3'] {
        background: url('../images/heroes/03/portrait-wide.png') no-repeat right / contain, url('../images/backgrounds/background29.png');
    }
    .build-list-item .hero-icon[data-career='4'] {
        background: url('../images/heroes/04/portrait-wide.png') no-repeat right / contain, url('../images/backgrounds/background29.png');
    }
    .build-list-item .hero-icon[data-career='5'] {
        background: url('../images/heroes/05/portrait-wide.png') no-repeat right / contain, url('../images/backgrounds/background29.png');
    }
    .build-list-item .hero-icon[data-career='6'] {
        background: url('../images/heroes/06/portrait-wide.png') no-repeat right / contain, url('../images/backgrounds/background29.png');
    }
    .build-list-item .hero-icon[data-career='7'] {
        background: url('../images/heroes/07/portrait-wide.png') no-repeat right / contain, url('../images/backgrounds/background29.png');
    }
    .build-list-item .hero-icon[data-career='8'] {
        background: url('../images/heroes/08/portrait-wide.png') no-repeat right / contain, url('../images/backgrounds/background29.png');
    }
    .build-list-item .hero-icon[data-career='9'] {
        background: url('../images/heroes/09/portrait-wide.png') no-repeat right / contain, url('../images/backgrounds/background29.png');
    }
    .build-list-item .hero-icon[data-career='10'] {
        background: url('../images/heroes/10/portrait-wide.png') no-repeat right / contain, url('../images/backgrounds/background29.png');
    }
    .build-list-item .hero-icon[data-career='11'] {
        background: url('../images/heroes/11/portrait-wide.png') no-repeat right / contain, url('../images/backgrounds/background29.png');
    }
    .build-list-item .hero-icon[data-career='12'] {
        background: url('../images/heroes/12/portrait-wide.png') no-repeat right / contain, url('../images/backgrounds/background29.png');
    }
    .build-list-item .hero-icon[data-career='13'] {
        background: url('../images/heroes/13/portrait-wide.png') no-repeat right / contain, url('../images/backgrounds/background29.png');
    }
    .build-list-item .hero-icon[data-career='14'] {
        background: url('../images/heroes/14/portrait-wide.png') no-repeat right / contain, url('../images/backgrounds/background29.png');
    }
    .build-list-item .hero-icon[data-career='15'] {
        background: url('../images/heroes/15/portrait-wide.png') no-repeat right / contain, url('../images/backgrounds/background29.png');
    }
    .build-list-item .hero-icon[data-career='16'] {
        background: url('../images/heroes/16/portrait-wide.png') no-repeat right / contain, url('../images/backgrounds/background29.png');
    }
    .build-list-item .hero-icon[data-career='17'] {
        background: url('../images/heroes/17/portrait-wide.png') no-repeat right / contain, url('../images/backgrounds/background29.png');
    }
    .build-list-item .hero-icon[data-career='18'] {
        background: url('../images/heroes/18/portrait-wide.png') no-repeat right / contain, url('../images/backgrounds/background29.png');
    }
    .build-list-item .hero-icon[data-career='19'] {
        background: url('../images/heroes/19/portrait-wide.png') no-repeat right / contain, url('../images/backgrounds/background29.png');
    }
    .hero-icon-container {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)) !important;
    }
}
@media (min-width: 500px) and (max-width: 750px) {
    .hero-character-icons>.character-icon {        
        width: 72px !important;
    }
    .hero-character-icons {
        grid-column-gap: 0 !important;
    }
}
@media (max-width: 1000px) {
    .hero-icon-container .hero-select-icon {
        height: 52px !important;
    }
    .hero-icon-container {
        padding: 10px !important;
    }
    .page-title-label {
        background: url('../../assets/images/labels/header-03.png') center / auto no-repeat;
        width: 500px !important;
        left: calc(50% - 250px) !important;
        top: 10px;
    }
    .page-title-label-background {
        display: none !important;
    }
    .hero-page {
        width: auto !important;
        grid-template-columns: 100% !important;
        grid-template-areas: "heroLeftContainer" "heroContainer" "heroInventory" !important;
    }
    .hero-overview-container {
        height: auto !important;
    }
    .hero-page .hero-details-container {
        grid-template-columns: 1fr !important;
        grid-row-gap: 20px !important;
        grid-template-areas: "heroSummary" "heroPassive" "heroSkill" "heroPerks" !important;
    }
    .hero-talents-container {
        height: auto !important;  
    }
    .talent-name {
        font-size: 115% !important;    
    }
    .hero-talents-grid .talent-icon {
        background-size: cover !important;
    }
    .talent-button-wrapper .talent-name {
        font-size: 120% !important;    
    }
    .hero-talents-container::after {
        top: 0;
    }
    .hero-talent {    
      box-shadow: inset 0px 10px 10px -10px white, -1px -1px 0 0 #564640 !important;
      font-size: 160%;
    }
    .hero-talents-grid {
        position: relative !important;
        grid-template-rows: 40px repeat(24, 88px) !important;
        grid-template-columns: 1fr !important;
        grid-template-areas: "heroTalentsHeader" 
                            "heroTalentLevel5" 
                            "heroTalent1" 
                            "heroTalent2"
                            "heroTalent3"
                            "heroTalentLevel10"
                            "heroTalent4"
                            "heroTalent5"
                            "heroTalent6"
                            "heroTalentLevel15"
                            "heroTalent7"
                            "heroTalent8"
                            "heroTalent9"
                            "heroTalentLevel20"
                            "heroTalent10"
                            "heroTalent11"
                            "heroTalent12"
                            "heroTalentLevel25"
                            "heroTalent13"
                            "heroTalent14"
                            "heroTalent15"
                            "heroTalentLevel30"
                            "heroTalent16"
                            "heroTalent17"
                            "heroTalent18" !important;
    }

    .talent-lock-icon.level-5 {
        grid-area: heroTalentLevel5;
    }
    .talent-lock-icon.level-10 {
        grid-area: heroTalentLevel10;
    }
    .talent-lock-icon.level-15 {
        grid-area: heroTalentLevel15;
    }
    .talent-lock-icon.level-20 {
        grid-area: heroTalentLevel20;
    }
    .talent-lock-icon.level-25 {
        grid-area: heroTalentLevel25;
    }
    .talent-lock-icon.level-30 {
        grid-area: heroTalentLevel30;
    }

    .hero-talent[data-talent="1"][data-tier="1"] {
        grid-area: heroTalent1;
    }        
    .hero-talent[data-talent="2"][data-tier="1"] {
        grid-area: heroTalent2;
    }        
    .hero-talent[data-talent="3"][data-tier="1"] {
        grid-area: heroTalent3;
    }  
    .hero-talent[data-talent="1"][data-tier="2"] {
        grid-area: heroTalent4;
    }        
    .hero-talent[data-talent="2"][data-tier="2"] {
        grid-area: heroTalent5;
    }        
    .hero-talent[data-talent="3"][data-tier="2"] {
        grid-area: heroTalent6;
    }        
    .hero-talent[data-talent="1"][data-tier="3"] {
        grid-area: heroTalent7;
    }
    .hero-talent[data-talent="2"][data-tier="3"] {
        grid-area: heroTalent8;
    }        
    .hero-talent[data-talent="3"][data-tier="3"] {
        grid-area: heroTalent9;
    }        
    .hero-talent[data-talent="1"][data-tier="4"] {
        grid-area: heroTalent10;
    }        
    .hero-talent[data-talent="2"][data-tier="4"] {
        grid-area: heroTalent11;
    }  
    .hero-talent[data-talent="3"][data-tier="4"] {
        grid-area: heroTalent12;
    }
    .hero-talent[data-talent="1"][data-tier="5"] {
        grid-area: heroTalent13;
    }        
    .hero-talent[data-talent="2"][data-tier="5"] {
        grid-area: heroTalent14;
    }        
    .hero-talent[data-talent="3"][data-tier="5"] {
        grid-area: heroTalent15;
    }
    .hero-talent[data-talent="1"][data-tier="6"] {
        grid-area: heroTalent16;
    }        
    .hero-talent[data-talent="2"][data-tier="6"] {
        grid-area: heroTalent17;
    }        
    .hero-talent[data-talent="3"][data-tier="6"] {
        grid-area: heroTalent18;
    }
    .build-details-container {
        grid-template-areas:
            "heroDetails"
            "buildName"
            "buildDescription" !important;
        grid-template-columns: 1fr !important;
        grid-auto-flow: column !important;
        grid-template-rows: unset !important;
    }
}

@media (min-width: 1000px) and (max-width: 1899px) {
    .hero-page {
        width: auto !important;
        grid-template-columns: auto !important;
        grid-template-areas: "heroLeftContainer" "heroContainer" "heroInventory" !important;
    }
}
@media (min-width: 1000px) and (max-width: 1299px) {
    .hero-page .hero-select-container {
        grid-template-rows: 33px auto auto;
    }
    .talent-name {
        font-size: 115% !important;    
    }
    .hero-talent .talent-icon {
        background-size: cover !important;
    }
}
@media (max-width: 1299px) {
    .view-build-page {
        grid-template-areas:
            "buildMainContainer"
            "buildLeftContainer" !important;
        grid-template-columns: 1fr !important;
        grid-template-rows: auto auto !important;
    }
}
@media (min-width: 1300px) and (max-width: 1899px) {
    .hero-overview-container {
        background-size: 100% auto !important;
    }
    .hero-page .hero-icon-container {
        grid-template-columns: unset !important;
        grid-template-rows: 1fr !important;
        grid-auto-flow: column !important;
        grid-auto-columns: 1fr;
    }
    .hero-icon-container {
        display: grid;
        width: calc(100% - 46px);
        grid-template-columns: unset !important;
        grid-auto-columns: 1fr;
        grid-auto-flow: column;
        justify-content: center;
    }
    .view-build-page .build-list-item {
        grid-template-areas:
            "heroIcon buildDescription buildRating"
            "heroIcon buildWeapons buildWeapons"
            "heroIcon buildTraits buildTraits"
            "buildFooter buildFooter buildFooter" !important;
        grid-template-columns: auto 1fr auto !important;
        grid-template-rows: auto auto auto 40px !important;
    }
    .view-build-page .build-list-item .build-creation-info {
        grid-template-columns: min-content auto;
        grid-template-rows: auto auto;
        grid-auto-flow: row !important;
    }    
    .view-build-page .build-list-item .date-updated {
        grid-column: 1 / 3;
    }
}
@media (max-width: 699px) {
    .hero-icon-container {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)) !important;
    }
}
@media (min-width: 700px) and (max-width: 1899px) {
    .hero-icon-container {
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)) !important;
    }
}
@media (max-width: 1899px) {
    .edit-build-page {
        grid-template-areas:
            "buildLeftContainer"
            "buildMainContainer"
            "buildRightContainer" !important;
        grid-template-columns: 1fr !important;
        grid-template-rows: auto auto auto !important;
    }
    .hero-icon-container .hero-select-icon {
        width: 100%;
        height: 74px;
    }
    .hero-icon-container .hero-select-icon[data-career='1'] {
        background: url('../images/heroes/01/portrait-wide.png') right / contain, url('../images/backgrounds/background29.png');
    }
    .hero-icon-container .hero-select-icon[data-career='2'] {
        background: url('../images/heroes/02/portrait-wide.png') right / contain, url('../images/backgrounds/background29.png');
    }
    .hero-icon-container .hero-select-icon[data-career='3'] {
        background: url('../images/heroes/03/portrait-wide.png') right / contain, url('../images/backgrounds/background29.png');
    }
    .hero-icon-container .hero-select-icon[data-career='4'] {
        background: url('../images/heroes/04/portrait-wide.png') right / contain, url('../images/backgrounds/background29.png');
    }
    .hero-icon-container .hero-select-icon[data-career='5'] {
        background: url('../images/heroes/05/portrait-wide.png') right / contain, url('../images/backgrounds/background29.png');
    }
    .hero-icon-container .hero-select-icon[data-career='6'] {
        background: url('../images/heroes/06/portrait-wide.png') right / contain, url('../images/backgrounds/background29.png');
    }
    .hero-icon-container .hero-select-icon[data-career='7'] {
        background: url('../images/heroes/07/portrait-wide.png') right / contain, url('../images/backgrounds/background29.png');
    }
    .hero-icon-container .hero-select-icon[data-career='8'] {
        background: url('../images/heroes/08/portrait-wide.png') right / contain, url('../images/backgrounds/background29.png');
    }
    .hero-icon-container .hero-select-icon[data-career='9'] {
        background: url('../images/heroes/09/portrait-wide.png') right / contain, url('../images/backgrounds/background29.png');
    }
    .hero-icon-container .hero-select-icon[data-career='10'] {
        background: url('../images/heroes/10/portrait-wide.png') right / contain, url('../images/backgrounds/background29.png');
    }
    .hero-icon-container .hero-select-icon[data-career='11'] {
        background: url('../images/heroes/11/portrait-wide.png') right / contain, url('../images/backgrounds/background29.png');
    }
    .hero-icon-container .hero-select-icon[data-career='12'] {
        background: url('../images/heroes/12/portrait-wide.png') right / contain, url('../images/backgrounds/background29.png');
    }
    .hero-icon-container .hero-select-icon[data-career='13'] {
        background: url('../images/heroes/13/portrait-wide.png') right / contain, url('../images/backgrounds/background29.png');
    }
    .hero-icon-container .hero-select-icon[data-career='14'] {
        background: url('../images/heroes/14/portrait-wide.png') right / contain, url('../images/backgrounds/background29.png');
    }
    .hero-icon-container .hero-select-icon[data-career='15'] {
        background: url('../images/heroes/15/portrait-wide.png') right / contain, url('../images/backgrounds/background29.png');
    }
    .hero-icon-container .hero-select-icon[data-career='16'] {
        background: url('../images/heroes/16/portrait-wide.png') right / contain, url('../images/backgrounds/background29.png');
    }
    .hero-icon-container .hero-select-icon[data-career='17'] {
        background: url('../images/heroes/17/portrait-wide.png') right / contain, url('../images/backgrounds/background29.png');
    }
    .hero-icon-container .hero-select-icon[data-career='18'] {
        background: url('../images/heroes/18/portrait-wide.png') right / contain, url('../images/backgrounds/background29.png');
    }
    .hero-icon-container .hero-select-icon[data-career='19'] {
        background: url('../images/heroes/19/portrait-wide.png') right / contain, url('../images/backgrounds/background29.png');
    }
    .hero-icon-container .hero-select-icon[data-career='20'] {
        background: url('../images/heroes/20/portrait-wide.png') right / contain, url('../images/backgrounds/background29.png');
    }
    .hero-container {
        box-shadow: -1px 0 0 0 #564640;
    }
}

@media (min-width: 1900px) {
    .hero-page {
        grid-template-columns: 1fr 980px 1fr !important;
        grid-template-areas: "heroLeftContainer heroContainer heroInventory" !important;
    }
    .hero-page .hero-icon-container, .build-page .hero-icon-container {
        height: auto;
    }
    .hero-page .hero-character-icons, .build-page .hero-character-icons {
        display: none;
    }
    .hero-page .hero-select-icon, .build-page .hero-select-icon {
        display: block !important;
        width: calc(100% - 10px) !important;
        height: 80px !important;
        justify-self: center;
    }
    .build-list-container .hero-icon-container {
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)) !important;
    }
}
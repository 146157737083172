@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

html, body, #root, .App {
  min-height: 100%;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.app-container>* {
  color: #d4d4d4;
}
.app-container-frame {
    margin: 0 auto;
    margin: 40px 20px 20px;
    position: relative;
}
/* .app-container-frame {
  position: relative;
  top: 42px;
  width: calc(100% - 40px);
  background-color: #000000c2;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: -2px -2px 2px black;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 40px;
} */
.app-container {
  padding: 40px;
  box-shadow: -1px -1px 1px black;
  border-radius: 8px;
}
.hidden {
    display: none !important;
}
@font-face {
  font-family: caslon-antique;
  src: url('../../assets/fonts/CaslonAntique.ttf');
}

@font-face {
  font-family: caslon-antique-bold;
  src: url('../../assets/fonts/CaslonAntique-Bold.ttf');
}

html, body {
  font-family: caslon-antique;
}
p {
  margin: 0;
  padding: 0;
}

.page-title-label {
  text-align: center;
  width: 662px;
  height: 64px;
  position: absolute;
  top: 3px;
  z-index: 2;
  left: calc(50% - 331px);
  font-size: 1.4rem;
  color: #c15b24;
  line-height: 66px;
  text-transform: uppercase;
  font-family: 'caslon-antique-bold';
}

.page-title-label-background {
  width: 417px;
  height: 42px;
  position: absolute;
  top: 16px;
  z-index: 1;
  left: calc(50% - 208px);
  background: -webkit-linear-gradient(to bottom, #2b1212 35%, #000);
  background: linear-gradient(to bottom, #2b1212 35%, #000);
}

/* Reset Select */
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 0;
  box-shadow: none;
  background: #4d2323;
  background-image: none;
}
/* Remove IE arrow */
select::-ms-expand {
  display: none;
}
/* Custom Select */
.select-list, .multiselect-container {
  background: linear-gradient(to bottom, #2b1212 35%, #000);
  text-align: center;
  font-family: caslon-antique;
  border-image: url('../../assets/images/borders/border-13.png');
  border-image-width: auto;
  border-image-slice: 21;
  outline: none;
  color: #30e158 !important;
  border-style: solid;
  width: auto !important;
  padding: 5px;
  border-image-repeat: repeat;
  align-self: start;
  min-height: 50px;
  display: grid;
}
select {
  position: relative;
  display: flex;
  background: linear-gradient(to bottom, #2b1212 35%, #000);
  overflow: hidden;
  font-size: 1.3em;
}
select {
  flex: 1;
  padding: 0 .5em;
  color: #c15b24;
  cursor: pointer;
}
/* Transition */
select:hover::after {
  color: #f39c12;
}
input, textarea {
  font-family: 'caslon-antique';
}
select {
  text-align: center;
  font-family: 'caslon-antique';
  border-image: url('../../assets/images/borders/border-13.png');
  border-image-width: 25px;
  border-image-slice: 25;
}
select option {
  font-family: 'caslon-antique';
  font-size: 18px;
}

.scroll-01 {
  background: url('../../assets/images/scrollers/scroll-01.png');
}
.scroll-02 {
  background: url('../../assets/images/scrollers/scroll-02.png');
}
.scroll-03 {
  background: url('../../assets/images/scrollers/scroll-03.png');
}
.scroll-04 {
  background: url('../../assets/images/scrollers/scroll-04.png');
}
.scroll-05 {
  background: url('../../assets/images/scrollers/scroll-05.png');
}
.scroll-06 {
  background: url('../../assets/images/scrollers/scroll-06.png');
}
.scroll-07 {
  background: url('../../assets/images/scrollers/scroll-07.png');
}
.scroll-08 {
  background: url('../../assets/images/scrollers/scroll-08.png');
}
.scroll-09 {
  background: url('../../assets/images/scrollers/scroll-09.png');
}
.scroll-10 {
  background: url('../../assets/images/scrollers/scroll-10.png');
}
.scroll-11 {
  background: url('../../assets/images/scrollers/scroll-11.png');
}
.scroll-12 {
  background: url('../../assets/images/scrollers/scroll-12.png');
}
.scroll-13 {
  background: url('../../assets/images/scrollers/scroll-13.png');
}

.divider-03 {
  background: url('../../assets/images/dividers/divider-03.png') no-repeat center / 390px 26px;
}
.divider-03.top {
  background-position: top;
  padding-top: 48px;
}
.divider-03.bottom {
  background-position: bottom;
  padding-bottom: 48px;
}
.divider-06 {
  background: url('../../assets/images/dividers/divider-06.png') no-repeat center;
}
.divider-01 {
  background: url('../../assets/images/dividers/divider-01.png') no-repeat center / 419px 28px;
}
.divider-02 {
  background: url('../../assets/images/dividers/divider-02.png') no-repeat center;
}
.divider-04 {
  background: url('../../assets/images/dividers/divider-04.png') no-repeat center;
}
.divider-05 {
  background: url('../../assets/images/dividers/divider-05.png') no-repeat center;
}
.divider-07 {
  background: url('../../assets/images/dividers/divider-07.png') no-repeat center;
}
.divider-08 {
  background: url('../../assets/images/dividers/divider-08.png') no-repeat center;
}
.divider-09 {
  background: url('../../assets/images/dividers/divider-09.png') no-repeat center;
}
.divider-10 {
  background: url('../../assets/images/dividers/divider-10.png') no-repeat center / 82px 26px;
}
.divider-11 {
  background: url('../../assets/images/dividers/divider-11.png') no-repeat center;
}
.divider-12 {
  background: url('../../assets/images/dividers/divider-12.png') no-repeat center;
}
.divider-13 {
  background: url('../../assets/images/dividers/divider-13.png') no-repeat center;
}
.divider-14 {
  background: url('../../assets/images/dividers/divider-14.png') no-repeat center;
}
.divider-15 {
  background: url('../../assets/images/dividers/divider-15.png') no-repeat center;
}
.divider-16 {
  background: url('../../assets/images/dividers/divider-16.png') no-repeat center;
}
.divider-17 {
  background: url('../../assets/images/dividers/divider-17.png') no-repeat center;
}
.divider-18 {
  background: url('../../assets/images/dividers/divider-18.png') no-repeat center;
}
.divider-19 {
  background: url('../../assets/images/dividers/divider-19.png') no-repeat center;
}
.divider-20 {
  background: url('../../assets/images/dividers/divider-20.png') no-repeat center;
}

.button-02 {
  text-align: center;
  position: relative;
  max-width: 300px;
  justify-self: center;
  width: auto;
  padding: 0 20px;
  color: #928962 !important;
  background: url('../../assets/images/labels/label-06.png') no-repeat center;
  background-size: cover;
  align-content: center;
  display: grid;
  text-transform: uppercase;
  font-size: 120%;
  box-shadow: inset 0 0 75px black, inset 0 -5px 1px #3b3b3b, inset 0 5px 1px #979797 !important;
  text-decoration: none;
  height: 36px;
  margin: 5px;
  outline: none;
  cursor: pointer;
  letter-spacing: 2px;
  grid-template-rows: 1fr;
  align-items: center;
  border-image: url('../../assets/images/borders/border-04.png');
  border-image-slice: 15;
  border-image-width: 15px;
  border-style: solid;
  border-image-repeat: repeat;
}

.button-02::before {
  background: url('../../assets/images/borders/border-23.png') no-repeat;
  left: -12px;
  content: '';
}
.button-02:hover {
  box-shadow: inset 0 0 75px black, inset 0 -17px 30px rgb(179 0 0), inset 0 -5px 1px #3b3b3b, inset 0 5px 1px #979797 !important;
  cursor: pointer;
}
.button-02::after {
  background: url('../../assets/images/borders/border-23.png') no-repeat;
  transform: scaleX(-1);
  right: -12px;
  content: '';
}
.button-02::before, .button-02::after {
  position: absolute;
  content: '';
  height: calc(100% + 8px);
  width: 100%;  
  background-repeat: no-repeat; 
  top: -4px;
}

.button-01 a {
  color: #928962;
  height: 100%;
  display: grid;
  z-index: 1;
  align-content: center;
}

.button-01 {
  text-align: center;
  position: relative;
  max-width: 300px;
  justify-self: center;
  width: calc(100% - 50px);
  color: #928962;
  background: url('../../assets/images/labels/label-06.png') no-repeat center;
  background-color: black;
  background-size: cover;
  align-content: center;
  display: grid;
  text-transform: uppercase;
  font-size: 120%;
  box-shadow: inset 0 0 75px black, inset 0 -5px 1px #3b3b3b, inset 0 5px 1px #979797 !important;
  text-decoration: none;
  height: 58px;
  margin: 5px;
  outline: none;
  cursor: pointer;
  letter-spacing: 2px;
  grid-template-rows: 1fr;
  align-items: center;
  border-image: url('../../assets/images/borders/border-04.png');
  border-image-slice: 15;
  border-image-width: 15px;
  border-style: solid;
  border-image-repeat: repeat;
  box-sizing: border-box;
}

.button-01::before {
  background: url('../../assets/images/borders/border-28.png') no-repeat;
  left: -12px;
  content: '';
}
.edit-build-page[data-dirty="false"] .button-01:hover {
  box-shadow: none;
  cursor: initial;
}
.button-01:hover {
  box-shadow: inset 0 0 75px black, inset 0 -17px 30px rgb(179 0 0), inset 0 -5px 1px #3b3b3b, inset 0 5px 1px #979797 !important;
  cursor: pointer;
}
.button-01::after {
  background: url('../../assets/images/borders/border-28.png') no-repeat;
  transform: scaleX(-1);
  right: -12px;
  content: '';
}
.button-01::before, .button-01::after {
  position: absolute;
  content: '';
  height: calc(100% + 8px);
  width: 100%;  
  background-repeat: no-repeat; 
  top: -4px;
}
.chain-decoration {
  position: absolute;
  height: calc(100% + 44px);
  width: 20px;
  background-repeat: repeat-y;
}
.chain-decoration.left {
  top: -18px;
  left: calc(50% + 90px);
}
.chain-decoration.right {
  top: -18px;
  left: calc(50% - 90px);  
}
a {
  text-decoration: none;
  color: initial;
}

.header-underline {
    position: relative;
    width: 100%;
  }

.header-underline::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  background-image: linear-gradient(90deg,#808080b3 10%, #80808000);
}
.top-left-shadow {
  box-shadow: -1px -1px 0 0 #564640;
}
.left-shadow {
  box-shadow: -1px 0 0 0 #564640;
}
.top-shadow {
  box-shadow: 0 -1px 0 0 #564640;
}
.tab-button:not(.disabled) {
  cursor: pointer;
}
/* .tab-button[data-selected='true']:not(.disabled), .tab-button:not(.disabled):hover {
  background: #000;
  box-shadow: inset 0 9px 6px -8px white, inset 0 -9px 9px -12px white, inset 0 -9px 20px -6px #6a371d !important;
  color: #f0f0f0;
  background: black;
} */

.tab-button.disabled {
  filter: grayscale(1);
}

div#root[data-page-name='aboutPage'] .page-title-label::after {
  content: 'About';
}

div#root[data-page-name='heroesPage'] .page-title-label::after {
  content: 'Heroes';
}

div#root[data-page-name='buildsPage'] .page-title-label::after {
  content: 'Builds';
}

.tab-button {
    border-width: 1px;
    border-radius: 0;
    border-image: url('../../assets/images/borders/border-01.png');
    border-image-slice: 15;
    border-image-width: 15px;
    border-style: solid;
    border-image-repeat: repeat;
    box-shadow: inset 0 9px 6px -6px white, inset 0 -9px 20px -6px #6a371d !important;
    color: #f0f0f0;
    background: black;
    text-align: center;
    align-items: center;
    min-height: 33px;
    display: grid;
}
.text-box {
  background: url('../../assets/images/backgrounds/background18.png');
  border-image: url('../../assets/images/borders/border-02.png');
  border-image-slice: 15;
  border-image-width: 15px;
  border-style: solid;
  border-image-repeat: repeat;
  color: white;
  font-size: 1rem;
  padding: 10px;
  outline: 0;
}
.rg-icon {
  cursor: pointer;
  background: url('../../assets/images/icons/rg.png') center / 41px 41px;
  width: 41px;
  height: 41px;
}
.rg-icon.main-logo {
  position: fixed;
  right: 30px;
  top: 2px;
}
.rg-icon.main-logo:hover {
  background: url('../../assets/images/icons/rg-flip.png') center / 41px 41px;
}
#root.showNav .main-logo {
  display: none;
}

  
.container-tabs {
  box-shadow: -1px -1px 0 0 #564640;
}
div:not(.react-tabs__tab-panel--selected)[role='tabpanel'] {
display: none;
}
button {
font-family: 'caslon-antique-bold';
}
.user-icon {
  background: url('../../assets/images/icons/user-icon.png');
  position: fixed;
}

#saveIndicator {
  position: fixed;
  top: 70px;
  left: 50px;
  opacity: 0;  
  padding: 5px 10px;
  color: #f0d9af;
  z-index:100;
}
#saveIndicator.saved {
  animation: fade 4s linear;
}
@keyframes fade {
  0%,100% { opacity: 0 }
  7%,60% { opacity: 1 }
}
.hero-page, .build-page {
  max-width: 1920px;
  margin: 0 auto;
}

a {
  color: #0096fb;
}
.discord-button {
  top: 10px;
  height: 48px;
  width: 48px;
  background: url('../../assets/images/icons/discord.png') center / contain no-repeat;
  display: grid;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}
.hero-select-container {    
  display: grid;
  grid-template-columns: 1fr;
  justify-self: center;
  grid-area: heroSelect;
  grid-template-rows: 33px auto 1fr;
  align-self: start;
  width: 100%;
}
.hero-icon-container>* {
  background-repeat: no-repeat;
  cursor: pointer;
  background-position: center center;
  align-self: center;
  height: 100%;
  background-size: cover;
}
.hero-icon-container>*:not(:hover) {
  box-shadow: inset 0 0 0 1000px #00000038;
}
.hero-icon-container>*:hover, .hero-icon-container>*.selected {
  box-shadow: 0px 0px 8px 2px #ffd700;
  outline: 1px solid white;
}
.hero-icon[data-career='1'] {
  background-image: url('../../assets/images/heroes/01/portrait.png');
}
.hero-icon[data-career='2'] {
  background-image: url('../../assets/images/heroes/02/portrait.png');
}
.hero-icon[data-career='3'] {
  background-image: url('../../assets/images/heroes/03/portrait.png');
}
.hero-icon[data-career='4'] {
  background-image: url('../../assets/images/heroes/04/portrait.png');
}
.hero-icon[data-career='5'] {
  background-image: url('../../assets/images/heroes/05/portrait.png');
}
.hero-icon[data-career='6'] {
  background-image: url('../../assets/images/heroes/06/portrait.png');
}
.hero-icon[data-career='7'] {
  background-image: url('../../assets/images/heroes/07/portrait.png');
}
.hero-icon[data-career='8'] {
  background-image: url('../../assets/images/heroes/08/portrait.png');
}
.hero-icon[data-career='9'] {
  background-image: url('../../assets/images/heroes/09/portrait.png');
}
.hero-icon[data-career='10'] {
  background-image: url('../../assets/images/heroes/10/portrait.png');
}
.hero-icon[data-career='11'] {
  background-image: url('../../assets/images/heroes/11/portrait.png');
}
.hero-icon[data-career='12'] {
  background-image: url('../../assets/images/heroes/12/portrait.png');
}
.hero-icon[data-career='13'] {
  background-image: url('../../assets/images/heroes/13/portrait.png');
}
.hero-icon[data-career='14'] {
  background-image: url('../../assets/images/heroes/14/portrait.png');
}
.hero-icon[data-career='15'] {
  background-image: url('../../assets/images/heroes/15/portrait.png');
}
.hero-icon[data-career='16'] {
  background-image: url('../../assets/images/heroes/16/portrait.png');
}
.hero-icon[data-career='17'] {
  background-image: url('../../assets/images/heroes/17/portrait.png');
}
.hero-icon[data-career='18'] {
  background-image: url('../../assets/images/heroes/18/portrait.png');
}
.hero-icon[data-career='19'] {
  background-image: url('../../assets/images/heroes/19/portrait.png');
}
.hero-icon[data-career='20'] {
  background-image: url('../../assets/images/heroes/20/portrait.png');
}
.hero-icon-container {
  display: grid;
  width: calc(100% - 46px);
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
  justify-self: center;
  padding: 20px;
  z-index: 1;
  grid-auto-rows: auto;
  margin: 0 auto;
}
.hero-select-header {
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: bottom;
  display: grid;
  align-content: center;
  grid-template-rows: 1fr;
  background-color: black;
  cursor: pointer;
}
.hero-select-header-background {
  height: calc(100% - 6px);
  align-self: center;
  display: grid;
}
.hero-select-header p {
  color: #c15b24;
  align-self: center;
  font-size: 120%;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.kruber-character-icon:before {
  background: url('../../assets/images/heroes/hero-class-01.png');
}
.bardin-character-icon:before {
  background: url('../../assets/images/heroes/hero-class-02.png');
}
.kerillian-character-icon:before {
  background: url('../../assets/images/heroes/hero-class-03.png');
}
.saltzpyre-character-icon:before {
  background: url('../../assets/images/heroes/hero-class-04.png');
}
.sienna-character-icon:before {
  background: url('../../assets/images/heroes/hero-class-05.png');
}
.hero-character-icons {
  display: grid;
  grid-auto-columns: min-content;
  grid-column-gap: 10px;
  grid-template-rows: auto;
  padding: 2px;
  grid-auto-flow: column;
  justify-content: center;
}
.hero-character-icons>.character-icon:before {
  content: '';
}

.hero-character-icons>.character-icon {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  width: 80px;
  height: 80px;
}

  .hero-character-icons {
      display: grid;
  }
  .hero-select-container[data-hero='1'] .hero-select-icon:not([data-hero='1']) {
      display: none;
  }
  .hero-select-container[data-hero='2'] .hero-select-icon:not([data-hero='2']) {
      display: none;
  }
  .hero-select-container[data-hero='3'] .hero-select-icon:not([data-hero='3']) {
      display: none;
  }
  .hero-select-container[data-hero='4'] .hero-select-icon:not([data-hero='4']) {
      display: none;
  }
  .hero-select-container[data-hero='5'] .hero-select-icon:not([data-hero='5']) {
      display: none;
  }
  .hero-select-container[data-hero='6'] .hero-select-icon:not([data-hero='6']) {
      display: none;
  }
  .hero-select-icon {
      width: 100px;
      height: 130px;
      justify-self: center;
      background-repeat: no-repeat !important;
  }
/*   .character-icon[data-selected='true']::after {
    border: solid #f0d9af;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    content: '';
    transform: rotate(135deg);
    position: absolute;
    left: calc(50% + 39px);
    top: calc(50% - 4px);
  } */
  
  .character-icon::before {
    display: inline-block;
    content: '';
    position: absolute;
    background-size: 57%;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
  }

.hero-icon-wrapper {
    box-shadow: inset 0 0 10px #000000;
}
.character-icon[data-selected='true'], .character-icon:hover {
  background: url('../../assets/images/icons/character-icon-selected.png') center / contain no-repeat;
}
.character-icon[data-selected='false'] {
  cursor: pointer;
}

.hero-select-header-background p {
  text-align: center;
}
.hero-page .hero-select-container {
  box-shadow: -1px -1px 0 0 #564640;
}
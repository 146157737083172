  .app-navigation-container {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: 1fr;
    grid-template-rows: max-content;
    padding: 0;  
    z-index: 2;
    position: relative;
  }
  .navigation-menu-page {
    top: 50px;
    position: relative;
    padding-bottom: 20px;
  }
  #root.showNav .navigation-menu-page {
    display: block;
  }
  #root.showNav .app-container-frame {
    display: none;
  }
  #root.showNav .page-title {
      display: none;
  }
  #root[data-pagename='menuPage'] .app-container-frame, #root[data-pagename='menuPage'] .page-title {
    display: none;
  }
#root.loggedIn .loginPageMenuButton {
    display: none;
}
#root:not(.loggedIn) .userPageMenuButton, #root:not(.loggedIn) .logoutMenuButton {
    display: none;
}
  .menu-title-label{
      z-index: 1;
      position: relative;
      text-align: center;
  }
  
.menu-title-label::after {
    content: "Ranald's Gift";
    text-transform: uppercase;
    position: relative;
    top: 0px;
    color: #c15b24;
    font-size: 3em;
    letter-spacing: 5px;
    text-shadow: 1px 1px black;
  }
  .menu-title-divider {
    height: 50px;
    z-index: 2;
    position: relative;
}
.menu-title-divider-bottom {
  height: 60px;
  z-index: 2;
  position: relative;
}
#backgroundVideo {
    position: relative;
    z-index: 100;
}


.nagivation-menu-button, .nagivation-menu-overlay {
    position: fixed;
    height: 40px;
    width: 90px;
    top: 0px;
    left: 20px;
    cursor: pointer;
}
.nagivation-menu-button {
    background: url('../../assets/images/icons/menu.png') no-repeat;
    background-size: contain;
    z-index: 100;
}
.nagivation-menu-button:hover .nagivation-menu-overlay {
    background: url('../../assets/images/icons/menu_active.png') no-repeat;
    background-size: contain;
    z-index: 101;
}
.showNav .nagivation-menu-button {
    display: none;
}

#root:not(.loggedIn) .auth-button, #root.loggedIn .unauth-button {
  display: none;
}
#root.showNav .close-menu-button {
  position: fixed;
  top: 20px;
  left: 30px;
  color: #fff;
  font-size: 2em;
  font-family: monospace;
  cursor: pointer;
}
.close-menu-button {
  background: url('../../assets/images/icons/close-icon.png') center / contain no-repeat;
  width: 40px;
  height: 40px;
}
.close-menu-button:hover {
  background: url('../../assets/images/icons/close-icon-selected.png') center / contain no-repeat;
}
.navigation-menu-page + .App .app-container-frame,
.navigation-menu-page + .App .page-title,
.navigation-menu-page + .App .navigation-menu-container,
.navigation-menu-page + .App .rg-icon.main-logo {
  display: none;
}
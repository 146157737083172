.user-info-container {
    color: #8bc34a;
    font-size: 1.5em;
    display: grid;
    grid-template-columns: max-content 1fr;
    justify-items: left;
    text-align: left;
    grid-column-gap: 15px;
    grid-row-gap: 5px;
    padding: 20px;
    position: relative;
    grid-row-gap: 10px;
}
.user-page[data-isauthor='false'] .button-container {
    display: none;
}
.user-info-container>span:nth-child(2n+1) {
    color:#808080;
}

.user-page {
    padding: 10px;
    display: grid;
    justify-content: center;
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    grid-template-columns: minmax(min-content,800px);
    grid-row-gap: 10px;
}
.user-build-lists {
    position: relative;
    box-shadow: -1px -1px 0 0 #564640;
}
.button-container {
    display: grid;
    padding: 20px 0 10px 0;
    max-width: 100%;
    width: 200px;
    margin: -20px auto 0 auto;
}
.user-page .build-list-container #userSelect {
    display: none;
}
.user-page .user-info-container a {    
    color: #0096fb;
}
.user-page[data-isauthor='false'] .builds-liked-tab {
    display: none;
}
.user-page .build-list-container #roleSelect {
    display: none;
}
.builds-button-container {
    display: grid;
    padding: 20px;
    max-width: 100%;
    width: auto;
    margin: 0px auto -20px auto;
}
.user-builds-bulk-updater .link-overlay {
    pointer-events: none;
}
.build-list-item-container.selected .build-list-item:before {
    box-shadow: 0 0 10px 2px #ffd700;
}

.user-builds-bulk-updater .build-list-item-container * {
    pointer-events: none;
}
.update-builds-button {
    max-width: none;
}
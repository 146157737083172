.edit-build-page {
    color: rgb(212, 212, 212);
    display: grid;
    grid-template-areas: "buildLeftContainer buildMainContainer buildRightContainer";
    grid-template-columns: 1fr 2.5fr 1fr;
    height: 100%;
    grid-template-rows: 100%;
    grid-column-gap: 20px;
}
.build-left-container {
    grid-area: buildLeftContainer;
    align-self: start;
}
.build-main-container {
    grid-area: buildMainContainer;
    display: grid;
    grid-template-rows: auto 1fr;
    box-shadow: -1px -1px 0 0 #564640;
}

.build-right-container {
    grid-area: buildRightContainer;
    align-self: start;
}
.build-group-container .build-list-item .talents, .build-group-container .build-list-item .weapons, .build-group-container .build-list-item .traits {
  display: none;
}
.build-group-container .build-list-item {
  grid-template-columns: 100px auto;
}
.build-group-container {
    height: 100%;
    display: grid;
    grid-template-rows: 60px calc(100% - 100px);
    grid-template-columns: 1fr;
    grid-template-areas: "buildGroupButtons" "buildListContainer";
    padding: 20px;
}
.simplebar-track .simplebar-scrollbar.simplebar-visible:before {
    opacity:0;
}
.simplebar-track.simplebar-vertical {
    right: 6px;
  }
  .simplebar-visible {
    background: white;
  }
  .simplebar-offset {
      padding-top: 4px;
  }
  .build-group-container [data-simplebar] {
    grid-area: buildListContainer;
    position: relative;
    background: url('../../assets/images/backgrounds/background12.png');
  }  
  .build-group-container [data-simplebar]::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-image: url('../../assets/images/borders/border-01.png');
    border-image-slice: 15;
    border-image-width: 15px;
    border-style: solid;
    box-shadow: -1px 0px 0 0 #564640;
  }  
  .build-group-container .build-list {
    padding: 20px;
  }
  .edit-build-page[data-dirty="false"] #saveBuildButton, .edit-build-page[data-valid="false"] #saveBuildButton {
    filter: grayscale(1);
    cursor: default;
  }
  .build-buttons-container {
    grid-area: buildGroupButtons;
    display: grid;
    align-content: center;
    padding: 20px;
    border-image: url('../../assets/images/borders/border-01.png');
    border-image-slice: 15;
    border-image-width: 15px;
    border-style: solid;
    box-shadow: -1px -1px 0 0 #564640;
    background: url('../../assets/images/backgrounds/background34.png');
    grid-gap: 10px
  }
.edit-build-page .build-details-container {
    display: grid;
    grid-template-rows: 40px auto;
    grid-template-columns: 2fr 1fr;
    grid-template-areas:
        "buildName buildName"
        "heroDetails empty"
        "buildDescription buildDescription";
    grid-row-gap: 20px;
  }
  .edit-build-page .build-details-container .hero-details-container {
    grid-template-rows: 1fr;
    grid-template-areas: "heroName" "heroSummary";
    grid-area: heroDetails;
  }
  .edit-build-page input {
    outline: none;
  }
  .edit-build-page .build-name-input {
      grid-area: buildName;
      color: #ff5722;
      font-size: 1.3em;
      text-align: center;
      text-transform: uppercase;
  }
.edit-build-page .hero-portrait {
  height: 175px;
  align-self: center;
}
.edit-build-page .hero-summary p:nth-child(2n+1) {
    display: none;
}
.edit-build-page .hero-summary {
    grid-template-rows: 32px 32px;
    grid-row-gap: 7px;
}

.build-navigation-container {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    box-shadow: -1px -1px 0 0 #564640;
  }

  .build-summary-container {
    display: grid;
}
.build-summary-tab {
    display: grid;
    grid-template-rows: 1fr auto;
    grid-template-areas: "buildDetails" "buildOptions" "buildSummary";
    border-image: url('../../assets/images/borders/border-01.png');
    border-image-slice: 15;
    border-image-width: 15px;
    border-style: solid;
    background: linear-gradient(to left, #000000d4, #000000c9 20%, #2a2a2a1c), url('../../assets/images/backgrounds/background14.png');
    grid-row-gap: 20px;
    padding-bottom: 20px;
}

.build-page[data-readonly='false'] .build-summary-tab {
  padding: 20px;
}
.build-details-container .hero-passive-container, .build-details-container .hero-skill-container, .build-details-container .hero-perks-container {
    display: none;
}
.build-options-container {
    grid-gap: 10px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)) !important;
    justify-content: center;
    grid-area: buildOptions;
}
.input-build-description {
    color: #f0f0f0;
    padding: 10px;
    font-size: 18px;
    resize: none;
    grid-area: buildDescription;
    outline: none;
    background-size: cover;
}
.edit-build-page .react-tabs__tab-list {
    display: grid;
    background: url('../../assets/images/labels/label-06.png');
    color: #928962;
    margin: 0;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
}
.react-tabs__tab-list { 
  border-bottom: none;
}
.react-tabs__tab-list li, .container-tabs-list li  {
    border-radius: 0;
    border-image: url('../../assets/images/borders/border-01.png');
    border-image-slice: 15;
    border-image-width: 15px;
    border-style: solid;
    border-image-repeat: repeat;
    box-shadow: inset 0 0 75px black, inset 0 6px 3px white;
    background: black;
}
ul[role='tablist'] li {
  color: #928962;
  padding: 0;
  height: 32px;
  display: grid;
  align-content: center;
  text-align: center;
  border-width: 1px;
}
ul[role="tablist"] li[aria-selected="true"] {
  background: #000;
  box-shadow: inset 0 9px 6px -6px white, inset 0 -9px 20px -6px #6a371d !important;
  color: #f0f0f0;
  background: black;
}

.edit-build-page .react-tabs__tab--selected {
    background: #000;
    box-shadow: inset 0 9px 6px -6px white, inset 0 -9px 20px -6px #6a371d !important;
    color: #f0f0f0;
    background: black;
}
.container-tabs-list {
  padding: 0;
  margin: 0;
  display: grid;
  grid-auto-flow: column;
}
.react-tabs__tab:focus {
    box-shadow: initial;
    outline: none;
}
.react-tabs__tab:focus::after {
    content: unset;
}
.react-tabs {    
    max-height: 100%;
    height: 100%;
}
.edit-build-page .react-tabs {    
    box-shadow: -1px -1px 0 0 #564640;
    display: block;
}
#buildSaveIndicator {
  position: fixed;
  top: 70px;
  left: 50px;
  opacity: 0;  
  padding: 5px 10px;
  color: #f0d9af;
  z-index:100;
}
#buildSaveIndicator.saved {
  animation: fade 4s linear;
}
@keyframes fade {
  0%,100% { opacity: 0 }
  7%,60% { opacity: 1 }
}
.build-information-container {
  grid-area: buildInformation;
  display: flex;
  flex-wrap: wrap;
  grid-column-gap: 10px;
  background-color: #440c0c3b;
  position: relative;
  padding: 3px 10px;
  border-top: 1px solid #f0d9af5e;
  border-bottom: 1px solid #f0d9af5e;
  z-index: 0;
  align-items: center;
  background: linear-gradient(45deg, #000000, #000000e0), url('../../assets/images/backgrounds/background19.png');
}
.build-information-container span:nth-child(2n+2), .build-information-container a {
  justify-self: left;
  font-size: 1.4em;
  color: #f0d9af;
  text-decoration: none;
}
.build-information-container>div {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 10px;
  align-items: center;
  text-align: right;
  grid-auto-columns: max-content;
}
.build-information-container a {
  color: #0096fb;
}
.edit-build-page[data-readonly='true'] a,
.build-page[data-readonly='true'] .build-like-container * {
  pointer-events: initial;
}
.edit-build-page[data-readonly='false'] .read-only-container {
  display: none;
}
.edit-build-page[data-readonly='true'] .build-details-container {
  grid-template-areas:
      "heroDetails buildName"
      "heroDetails buildInformation"
      "buildDescription buildDescription"
      "buildOptions buildOptions";
}
.build-page[data-liked='false'] .build-information-container .unlike-icon { display: none; }
.build-page[data-liked='true'] .build-information-container .like-icon { display: none; }
.like-icon, .unlike-icon {
  cursor: pointer;
}
.build-like-container i {
  color: #f0d9af;
}

.build-like-container span {
  align-self: end;
}

@keyframes pulse_animation {
  0% { font-size: 1.2em; }
  10% { font-size: 1.3em; }
  20% { font-size: 1.2em; }
}

.build-page[data-dirty="true"][data-valid="true"] #saveBuildButton {
  animation-name: pulse_animation;
  animation-duration: 3000ms;
  transform-origin:70% 70%;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.build-page[data-readonly='true'] .build-main-container>.react-tabs>li:nth-child(n+2) {
  display: none;
}

input#search_input::placeholder {
  color: #f0d9af !important;
}
.multiselect-container .searchBox::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  background-image: linear-gradient(90deg,#808080b3 10%, #80808000);
}
.edit-build-page .hero-select-container {
  box-shadow: -1px 0px 0 0 #564640;
}
.build-page[data-valid='false'] span#saveBuildButton:hover {
  box-shadow: inset 0 0 75px black, inset 0 -5px 1px #3b3b3b, inset 0 5px 1px #979797 !important;
}
.build-page[data-dirty='false'] span#saveBuildButton:hover {
  box-shadow: inset 0 0 75px black, inset 0 -5px 1px #3b3b3b, inset 0 5px 1px #979797 !important
}
.build-page[data-readonly='true'] .build-main-summary-container>* {
  margin: 0 20px;
}

.build-header-details-container {
  margin: 0 !important;
}
.edit-build-page[data-readonly='true'] .build-information-container * {
  font-size: 1.2rem !important;
}
.build-details-container .quill {
  grid-area: buildDescription;
}
.build-page[data-fresh='true'] .view-build-button {
  display: none;
}
input.build-name-input {
  text-transform: initial !important;
}
span.save-info {
  text-align: center;
}
.build-page[data-valid='true'] .save-info {
  display: none;
}
.build-page[data-readonly='false'] .build-options-container {
  position: relative;
  padding-top: 60px !important;
}
.build-page[data-readonly='false'] .build-options-container:before {
  content: 'These tags are all optional.';
  position: absolute;
  top: 33px;
  left: calc(50% - 72px);
}
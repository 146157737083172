

.build-list {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-flow: row;
    grid-row-gap: 15px;
    grid-auto-rows: auto;
    padding: 20px;
    align-content: start;
}
::-webkit-scrollbar {
    display:none;
}
.simplebar-content-wrapper {
    height: calc(100% - 3px) !important;
}
.build-list-header {
    font-size: 1.4rem;
    text-align: left;
    color: #c15b24;
    text-transform: uppercase;
    font-family: 'caslon-antique-bold';
}
.build-creation-info {
    grid-column-gap: 5px;
    display: grid;
    grid-auto-flow: column;
}
.build-list-container {
    display: grid;
    grid-template-areas:
        "heroSelect"
        "buildFilters"
        "buildList"
        "pageControls";
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    margin: 0 auto;
}
.select-filters-container {
    grid-area: buildFilters;
    display: grid;
    grid-gap: 10px;
    padding: 20px;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)) !important;
}
.build-list-container .hero-select-container {
    width: 100%;
}
.page-controls {
    grid-area: pageControls;    
    display: grid;
    grid-auto-flow: column;
    width: 100%;
    grid-auto-columns: 1fr;
    box-shadow: -1px -1px 0 0 #564640;
    margin-bottom: -2px;
}
.build-list-label {
    text-align: left;
    color: #f0d9af;
}
.build-list-container .hero-icon-container .hero-select-icon[data-career='1'] {
    background: url('../../assets/images/heroes/01/portrait-wide.png') right / contain, url('../../assets/images/backgrounds/background29.png');
}
.build-list-container .hero-icon-container .hero-select-icon[data-career='2'] {
    background: url('../../assets/images/heroes/02/portrait-wide.png') right / contain, url('../../assets/images/backgrounds/background29.png');
}
.build-list-container .hero-icon-container .hero-select-icon[data-career='3'] {
    background: url('../../assets/images/heroes/03/portrait-wide.png') right / contain, url('../../assets/images/backgrounds/background29.png');
}
.build-list-container .hero-icon-container .hero-select-icon[data-career='4'] {
    background: url('../../assets/images/heroes/04/portrait-wide.png') right / contain, url('../../assets/images/backgrounds/background29.png');
}
.build-list-container .hero-icon-container .hero-select-icon[data-career='5'] {
    background: url('../../assets/images/heroes/05/portrait-wide.png') right / contain, url('../../assets/images/backgrounds/background29.png');
}
.build-list-container .hero-icon-container .hero-select-icon[data-career='6'] {
    background: url('../../assets/images/heroes/06/portrait-wide.png') right / contain, url('../../assets/images/backgrounds/background29.png');
}
.build-list-container .hero-icon-container .hero-select-icon[data-career='7'] {
    background: url('../../assets/images/heroes/07/portrait-wide.png') right / contain, url('../../assets/images/backgrounds/background29.png');
}
.build-list-container .hero-icon-container .hero-select-icon[data-career='8'] {
    background: url('../../assets/images/heroes/08/portrait-wide.png') right / contain, url('../../assets/images/backgrounds/background29.png');
}
.build-list-container .hero-icon-container .hero-select-icon[data-career='9'] {
    background: url('../../assets/images/heroes/09/portrait-wide.png') right / contain, url('../../assets/images/backgrounds/background29.png');
}
.build-list-container .hero-icon-container .hero-select-icon[data-career='10'] {
    background: url('../../assets/images/heroes/10/portrait-wide.png') right / contain, url('../../assets/images/backgrounds/background29.png');
}
.build-list-container .hero-icon-container .hero-select-icon[data-career='11'] {
    background: url('../../assets/images/heroes/11/portrait-wide.png') right / contain, url('../../assets/images/backgrounds/background29.png');
}
.build-list-container .hero-icon-container .hero-select-icon[data-career='12'] {
    background: url('../../assets/images/heroes/12/portrait-wide.png') right / contain, url('../../assets/images/backgrounds/background29.png');
}
.build-list-container .hero-icon-container .hero-select-icon[data-career='13'] {
    background: url('../../assets/images/heroes/13/portrait-wide.png') right / contain, url('../../assets/images/backgrounds/background29.png');
}
.build-list-container .hero-icon-container .hero-select-icon[data-career='14'] {
    background: url('../../assets/images/heroes/14/portrait-wide.png') right / contain, url('../../assets/images/backgrounds/background29.png');
}
.build-list-container .hero-icon-container .hero-select-icon[data-career='15'] {
    background: url('../../assets/images/heroes/15/portrait-wide.png') right / contain, url('../../assets/images/backgrounds/background29.png');
}
.build-list-container .hero-icon-container .hero-select-icon[data-career='16'] {
    background: url('../../assets/images/heroes/16/portrait-wide.png') right / contain, url('../../assets/images/backgrounds/background29.png');
}
.build-list-container .hero-icon-container .hero-select-icon[data-career='17'] {
    background: url('../../assets/images/heroes/17/portrait-wide.png') right / contain, url('../../assets/images/backgrounds/background29.png');
}
.build-list-container .hero-icon-container .hero-select-icon[data-career='18'] {
    background: url('../../assets/images/heroes/18/portrait-wide.png') right / contain, url('../../assets/images/backgrounds/background29.png');
}
.build-list-container .hero-icon-container .hero-select-icon[data-career='19'] {
    background: url('../../assets/images/heroes/19/portrait-wide.png') right / contain, url('../../assets/images/backgrounds/background29.png');
}
.build-list-container .hero-select-icon[data-career='20'] {
    background: url('../../assets/images/heroes/20/portrait-wide.png') right / contain, url('../../assets/images/backgrounds/background29.png');
}
.build-list-container .hero-icon-container .hero-select-icon {
    width: 100%;
    height: 74px;
    background-position-x: right !important;
}
.build-list-container[data-collapsed='true'] .build-list-filters-container {
    display: none;
}
.build-list-container .build-list-filters-header:after {
    position: absolute;
    right: 0;
    margin-right: 12px;
    font-size: 1.2rem;
}
.build-list-container[data-collapsed='true'] .build-list-filters-header:after {
    content: '+';
}
.build-list-container[data-collapsed='false'] .build-list-filters-header:after {
    content: '--';
    margin-bottom: 2px;
}

.build-list-filters-header {
    position: relative;
}
.build-list-container[data-hide-filters='true'] .build-list-filters-header {
    display: none;
}
.build-list-container[data-page-number='1'] .previous-page-button {
    display: none;
}
.build-list-container[data-last-page='true'] .next-page-button {
    display: none;
}
.page-controls button {
    font-size: 1rem;
}
.build-list-container[data-hide-filters='true'] .build-list-filters-container {
    display: none;
}
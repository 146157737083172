.border-01 {
    border-image: url('../../assets/images/borders/border-01.png'); 
    border-image-slice: 15;
    border-image-width: 15px;
    border-style: solid;
    border-image-repeat: repeat;
  }
  .border-02 {
    border-image: url('../../assets/images/borders/border-02.png');
    border-image-slice: 15;
    border-image-width: 15px;
    border-style: solid;
    border-image-repeat: repeat;
  }
  .border-03 {
    border-image: url('../../assets/images/borders/border-03.png');
    border-image-slice: 18;
    border-image-width: 18px;
    border-style: solid;
    border-image-repeat: repeat;
  }
  .border-04 {
    border-image: url('../../assets/images/borders/border-04.png');
    border-image-slice: 15;
    border-image-width: 15px;
    border-style: solid;
    border-image-repeat: repeat;
  }
  .border-05 {
    border-image: url('../../assets/images/borders/border-05.png');
    border-image-slice: 15;
    border-image-width: 15px;
    border-style: solid;
    border-image-repeat: repeat;
  }
  .border-06 {
    border-image: url('../../assets/images/borders/border-06.png');
    border-image-slice: 120;
    border-image-width: 120px;
    border-style: solid;
    border-image-repeat: repeat;
  }
  .border-07 {
    border-image: url('../../assets/images/borders/border-07.png');
    border-image-slice: 15;
    border-image-width: 15px;
    border-style: solid;
    border-image-repeat: repeat;
  }
  .border-08 {
    border-image: url('../../assets/images/borders/border-08.png');
    border-image-slice: 15;
    border-image-width: 15px;
    border-style: solid;
    border-image-repeat: repeat;
  }
  .border-09 {
    border-image: url('../../assets/images/borders/border-09.png');
    border-image-slice: 62;
    border-image-width: 62px;
    border-style: solid;
    border-image-repeat: repeat;
    min-height: 124px;
  }
  .border-10 {
    border-image: url('../../assets/images/borders/border-10.png');
    border-image-slice: 15;
    border-image-width: 15px;
    border-style: solid;
    border-image-repeat: repeat;
  }
  .border-11 {
    border-image: url('../../assets/images/borders/border-11.png');
    border-image-slice: 15;
    border-image-width: 15px;
    border-style: solid;
    border-image-repeat: repeat; 
  }
  .border-12 {
    border-image: url('../../assets/images/borders/border-12.png');
    border-image-slice: 15;
    border-image-width: 15px;
    border-style: solid;
    border-image-repeat: repeat;
  }
  .border-13 {
    border-image: url('../../assets/images/borders/border-13.png');
    border-image-width: auto;
    border-image-slice: 21;
    border-style: solid;
    border-image-repeat: repeat;
  }
  .border-14 {
    border-image: url('../../assets/images/borders/border-14.png');
    border-image-slice: 15;
    border-image-width: 15px;
    border-style: solid;
    border-image-repeat: repeat;
  }
  .border-15 {
    border-image: url('../../assets/images/borders/border-15.png');
    border-image-slice: 15;
    border-image-width: 15px;
    border-style: solid;
    border-image-repeat: repeat;
  }
  .border-16 {
    border-image: url('../../assets/images/borders/border-16.png');
    border-image-slice: 15;
    border-image-width: 15px;
    border-style: solid;
  }
  .border-17 {
    border-image: url('../../assets/images/borders/border-17.png');
    border-image-slice: 15;
    border-image-width: 15px;
    border-style: solid;
    border-image-repeat: repeat;
  }
  .border-18 {
    border-image: url('../../assets/images/borders/border-18.png');
    border-image-slice: 15;
    border-image-width: 15px;
    border-style: solid;
    border-image-repeat: repeat;
  }
  .border-19 {
    border-image: url('../../assets/images/borders/border-19.png');
    border-image-slice: 15;
    border-image-width: 15px;
    border-style: solid;
    border-image-repeat: repeat;
  }
  .border-20 {
    border-image: url('../../assets/images/borders/border-20.png');
    border-image-slice: 15;
    border-image-width: 15px;
    border-style: solid;
    border-image-repeat: repeat;
  }
  .border-21 {
    border-image: url('../../assets/images/borders/border-21.png');
    border-image-slice: 15;
    border-image-width: 15px;
    border-style: solid;
    border-image-repeat: repeat;
  }
  .border-22 {
    border-image: url('../../assets/images/borders/border-22.png');
    border-image-slice: 15;
    border-image-width: 15px;
    border-style: solid;
    border-image-repeat: repeat;
  }
  .border-23 {
    border-image: url('../../assets/images/borders/border-23.png');
    border-image-slice: 15;
    border-image-width: 15px;
    border-style: solid;
    border-image-repeat: repeat;
  }
  .border-24 {
    border-image: url('../../assets/images/borders/border-24.png');
    border-image-slice: 15;
    border-image-width: 15px;
    border-style: solid;
    border-image-repeat: repeat;
  }
  .border-25 {
    border-image: url('../../assets/images/borders/border-25.png');
    border-image-slice: 15;
    border-image-width: 15px;
    border-style: solid;
    border-image-repeat: repeat;
  }
  .border-26 {
    border-image: url('../../assets/images/borders/border-26.png');
    border-image-slice: 15;
    border-image-width: 15px;
    border-style: solid;
    border-image-repeat: repeat;
  }
  .border-27 {
    border-image: url('../../assets/images/borders/border-27.png');
    border-image-slice: 15;
    border-image-width: 15px;
    border-style: solid;
    border-image-repeat: repeat;
  }
  .border-28 {
    border-image: url('../../assets/images/borders/border-28.png');
    border-image-slice: 15;
    border-image-width: 15px;
    border-style: solid;
    border-image-repeat: repeat;
  }
  .border-29 {
    border-image: url('../../assets/images/borders/border-29.png');
    border-image-slice: 15;
    border-image-width: 15px;
    border-style: solid;
    border-image-repeat: repeat;
  }
  .border-30 {
    border-image: url('../../assets/images/borders/border-30.png');
    border-image-slice: 15;
    border-image-width: 15px;
    border-style: solid;
    border-image-repeat: repeat;
  }
  .border-31 {
    border-image: url('../../assets/images/borders/border-31.png');
    border-image-slice: 67;
    border-image-width: 67px;
    border-style: solid;
    border-image-repeat: repeat;
  }
  .header-01 {
      background: url('../../assets/images/labels/header-01.png') center / 502px 68px no-repeat;
  }
  .header-02 {
      background: url('../../assets/images/labels/header-02.png');
  }
  .header-03 {
      background: url('../../assets/images/labels/header-03.png');
  }
  .border-32 {
    border-image: url('../../assets/images/borders/border-32.png');
    border-image-slice: 67;
    border-image-width: 67px;
    border-style: solid;
    border-image-repeat: repeat;
  }
  .border-33 {
    border-image: url('../../assets/images/borders/border-33.png');
    border-image-slice: 67;
    border-image-width: 67px;
    border-style: solid;
    border-image-repeat: repeat;
  }
  .border-34 {
    border-image: url('../../assets/images/borders/border-34.png');
    border-image-slice: 67;
    border-image-width: 67px;
    border-style: solid;
    border-image-repeat: repeat;
  }
  .border-35 {
    border-image: url('../../assets/images/borders/border-35.png');
    border-image-slice: 40;
    border-image-width: 40px;
    border-style: solid;
    border-image-repeat: repeat;
  }
  .border-36 {
    border-image: url('../../assets/images/borders/border-36.png');
    border-image-slice: 67;
    border-image-width: 67px;
    border-style: solid;
    border-image-repeat: repeat;
  }
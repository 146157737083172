#search_input {
    justify-self: center;
    margin: 0;
    padding: 0;
    cursor: pointer;
    width: auto;
    color: #30e158 !important;
}
.search-wrapper {
    justify-content: center;
    grid-template-rows: 1fr;
    grid-template-columns: min-content;
    position: relative;
    height: 100%;
    grid-auto-rows: 1fr;
    padding: 0 !important;
    display: flex;
    flex-direction: column;
}
.search-wrapper > span {
    align-self: center;
    background: none;
    font-size: 1.3em;
    margin: 0;
    color: #c15b24;
    z-index: 1;
    order: 2;
}
.build-options-container select {
    height: 50px;
    text-align-last: center;
}
.multiselect-container > .search-wrapper {
  border: none;
}
.searchBox::placeholder {
  color: #c15b24 !important;
  opacity: 1;
}
.searchBox {
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 1.3em;
}
.optionListContainer {
  background: #000 !important;
  font-size: 1.3em;
  top: 100%;
}
.optionContainer {
    border-radius: 0 !important;
}
.optionContainer li {
    font-size: 18px;
    padding: 5px 12px;
}
.build-options-container select:hover, .multiselect-container:hover {
    box-shadow: inset 0px 0px 20px -5px #c4531b;
    cursor: pointer;
}
.multiselect-container input {
    font-size: 1.3em;
    margin-top: 0;
    order: 1;
    color: #30e158 !important;
}
.multiselect-container input::placeholder {
  color: #30e158 !important;
}
#sortBySelect .search-wrapper > span > i {
  display: none;
}
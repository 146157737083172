.hero-1-skill {
    background: url('../images/heroes/01/skill.png');
}
.hero-1-passive {
    background: url('../images/heroes/01/passive.png');
}
.hero-1-portrait {
    background: url('../images/heroes/01/portrait.png');
}

.hero-2-skill {
    background: url('../images/heroes/02/skill.png');
}
.hero-2-passive {
    background: url('../images/heroes/02/passive.png');
}
.hero-2-portrait {
    background: url('../images/heroes/02/portrait.png');
}

.hero-3-skill {
    background: url('../images/heroes/03/skill.png');
}
.hero-3-passive {
    background: url('../images/heroes/03/passive.png');
}
.hero-3-portrait {
    background: url('../images/heroes/03/portrait.png');
}

.hero-4-skill {
    background: url('../images/heroes/04/skill.png');
}
.hero-4-passive {
    background: url('../images/heroes/04/passive.png');
}
.hero-4-portrait {
    background: url('../images/heroes/04/portrait.png');
}

.hero-5-skill {
    background: url('../images/heroes/05/skill.png');
}
.hero-5-passive {
    background: url('../images/heroes/05/passive.png');
}
.hero-5-portrait {
    background: url('../images/heroes/05/portrait.png');
}

.hero-6-skill {
    background: url('../images/heroes/06/skill.png');
}
.hero-6-passive {
    background: url('../images/heroes/06/passive.png');
}
.hero-6-portrait {
    background: url('../images/heroes/06/portrait.png');
}

.hero-7-skill {
    background: url('../images/heroes/07/skill.png');
}
.hero-7-passive {
    background: url('../images/heroes/07/passive.png');
}
.hero-7-portrait {
    background: url('../images/heroes/07/portrait.png');
}

.hero-8-skill {
    background: url('../images/heroes/08/skill.png');
}
.hero-8-passive {
    background: url('../images/heroes/08/passive.png');
}
.hero-8-portrait {
    background: url('../images/heroes/08/portrait.png');
}

.hero-9-skill {
    background: url('../images/heroes/09/skill.png');
}
.hero-9-passive {
    background: url('../images/heroes/09/passive.png');
}
.hero-9-portrait {
    background: url('../images/heroes/09/portrait.png');
}

.hero-10-skill {
    background: url('../images/heroes/10/skill.png');
}
.hero-10-passive {
    background: url('../images/heroes/10/passive.png');
}
.hero-10-portrait {
    background: url('../images/heroes/10/portrait.png');
}

.hero-11-skill {
    background: url('../images/heroes/11/skill.png');
}
.hero-11-passive {
    background: url('../images/heroes/11/passive.png');
}
.hero-11-portrait {
    background: url('../images/heroes/11/portrait.png');
}

.hero-12-skill {
    background: url('../images/heroes/12/skill.png');
}
.hero-12-passive {
    background: url('../images/heroes/12/passive.png');
}
.hero-12-portrait {
    background: url('../images/heroes/12/portrait.png');
}

.hero-13-skill {
    background: url('../images/heroes/13/skill.png');
}
.hero-13-passive {
    background: url('../images/heroes/13/passive.png');
}
.hero-13-portrait {
    background: url('../images/heroes/13/portrait.png');
}

.hero-14-skill {
    background: url('../images/heroes/14/skill.png');
}
.hero-14-passive {
    background: url('../images/heroes/14/passive.png');
}
.hero-14-portrait {
    background: url('../images/heroes/14/portrait.png');
}

.hero-15-skill {
    background: url('../images/heroes/15/skill.png');
}
.hero-15-passive {
    background: url('../images/heroes/15/passive.png');
}
.hero-15-portrait {
    background: url('../images/heroes/15/portrait.png');
}
.hero-16-skill {
    background: url('../images/heroes/16/skill.png');
}
.hero-16-passive {
    background: url('../images/heroes/16/passive.png');
}
.hero-16-portrait {
    background: url('../images/heroes/16/portrait.png');
}
.hero-17-skill {
    background: url('../images/heroes/17/skill.png');
}
.hero-17-passive {
    background: url('../images/heroes/17/passive.png');
}
.hero-17-portrait {
    background: url('../images/heroes/17/portrait.png');
}
.hero-18-skill {
    background: url('../images/heroes/18/skill.png');
}
.hero-18-passive {
    background: url('../images/heroes/18/passive.png');
}
.hero-18-portrait {
    background: url('../images/heroes/18/portrait.png');
}
.hero-19-skill {
    background: url('../images/heroes/19/skill.png');
}
.hero-19-passive {
    background: url('../images/heroes/19/passive.png');
}
.hero-19-portrait {
    background: url('../images/heroes/19/portrait.png');
}
.hero-20-skill {
    background: url('../images/heroes/20/skill.png');
}
.hero-20-passive {
    background: url('../images/heroes/20/passive.png');
}
.hero-20-portrait {
    background: url('../images/heroes/20/portrait.png');
}

.hero-1-render {
    background: url('../images/heroes/01/render.jpg');
}
.hero-2-render {
    background: url('../images/heroes/02/render.jpg');
}
.hero-3-render {
    background: url('../images/heroes/03/render.jpg');
}
.hero-4-render {
    background: url('../images/heroes/04/render.jpg');
}
.hero-5-render {
    background: url('../images/heroes/05/render.jpg');
}
.hero-6-render {
    background: url('../images/heroes/06/render.jpg');
}
.hero-7-render {
    background: url('../images/heroes/07/render.jpg');
}
.hero-8-render {
    background: url('../images/heroes/08/render.jpg');
}
.hero-9-render {
    background: url('../images/heroes/09/render.jpg');
}
.hero-10-render {
    background: url('../images/heroes/10/render.jpg');
}
.hero-11-render {
    background: url('../images/heroes/11/render.jpg');
}
.hero-12-render {
    background: url('../images/heroes/12/render.jpg');
}
.hero-13-render {
    background: url('../images/heroes/13/render.jpg');
}
.hero-14-render {
    background: url('../images/heroes/14/render.jpg');
}
.hero-15-render {
    background: url('../images/heroes/15/render.jpg');
}


.hero-1-portrait-alt {
    background: url('../images/heroes/01/portrait-alt.png');
}
.hero-2-portrait-alt {
    background: url('../images/heroes/02/portrait-alt.png');
}
.hero-3-portrait-alt {
    background: url('../images/heroes/03/portrait-alt.png');
}
.hero-4-portrait-alt {
    background: url('../images/heroes/04/portrait-alt.png');
}
.hero-5-portrait-alt {
    background: url('../images/heroes/05/portrait-alt.png');
}
.hero-6-portrait-alt {
    background: url('../images/heroes/06/portrait-alt.png');
}
.hero-7-portrait-alt {
    background: url('../images/heroes/07/portrait-alt.png');
}
.hero-8-portrait-alt {
    background: url('../images/heroes/08/portrait-alt.png');
}
.hero-9-portrait-alt {
    background: url('../images/heroes/09/portrait-alt.png');
}
.hero-10-portrait-alt {
    background: url('../images/heroes/10/portrait-alt.png');
}
.hero-11-portrait-alt {
    background: url('../images/heroes/11/portrait-alt.png');
}
.hero-12-portrait-alt {
    background: url('../images/heroes/12/portrait-alt.png');
}
.hero-13-portrait-alt {
    background: url('../images/heroes/13/portrait-alt.png');
}
.hero-14-portrait-alt {
    background: url('../images/heroes/14/portrait-alt.png');
}
.hero-15-portrait-alt {
    background: url('../images/heroes/15/portrait-alt.png');
}
.hero-16-portrait-alt {
    background: url('../images/heroes/16/portrait-alt.png');
}
.hero-17-portrait-alt {
    background: url('../images/heroes/17/portrait-alt.png');
}
.hero-18-portrait-alt {
    background: url('../images/heroes/18/portrait-alt.png');
}
.hero-19-portrait-alt {
    background: url('../images/heroes/19/portrait-alt.png');
}
.hero-20-portrait-alt {
    background: url('../images/heroes/20/portrait-alt.png');
}
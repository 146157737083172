.inventory-item-container {
    box-shadow: inset 0 40px 50px black;
}
.inventory-container { 
    color: #f0f0f0;
}
.hero-equipment-container {
    grid-area: heroEquipment;
    display: grid;
    color: #c15b24;
    grid-template-areas: "primaryContainer secondaryContainer";
    font-size: 132%;
    text-transform: uppercase;
    box-shadow: inset 0 40px 50px black;
    grid-template-columns: 1fr 1fr;
  }
  .primary-container {
    grid-area: primaryContainer;
    grid-template-rows: 35px 25px 1fr;
    display: grid;
    align-content: center;
    background-position-y: -10px;
    grid-template-areas: "primaryHeader" "primaryDivider" "primaryContainer";
  }
  .secondary-container {
    grid-area: secondaryContainer;
    grid-template-rows: 35px 25px 1fr;
    display: grid;  
    align-content: center;
    background-position-y: -10px;
    grid-template-areas: "secondaryHeader" "secondaryDivider" "secondaryContainer";
  }
  .primary-header {
    grid-area: primaryHeader;
    align-self: end;
  }
  .secondary-header {
    grid-area: secondaryHeader;
    align-self: end;
  }
  .primary-divider {
    grid-area: primaryDivider;
    height: 26px;
  }
  .secondary-divider {
    grid-area: secondaryDivider;
    height: 26px;
  }
  .weapon-container {
    display: flex;
    flex-wrap: wrap;
    justify-items: center;
    max-width: 325px;
    justify-content: center;
    grid-template-rows: 1fr 1fr;
    grid-row-gap: 7px;
    grid-column-gap: 7px;
    justify-self: center;
    margin: 12px;
  }
  .weapon-border, .trait-icon, .jewelry-icon {
    box-shadow: inset 0 2px 2px #fff;
}